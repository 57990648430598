import React, { lazy } from "react";
import { Switch, Route } from "react-router-dom";
import suspensify from "../boot/suspensify";

const VehiclesLayout = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesLayoutComponent" */
    "../components/layouts/VehiclesLayout"
  )
);

const VehiclesSettingsLayout = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesSettingsLayoutComponent" */
    "../components/layouts/vehicles/SettingsLayout"
  )
);

const VehiclesIndex = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesIndexComponent" */
    "../containers/vehicles/Index"
  )
);

const VehiclesVersions = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesVersionsComponent" */
    "../containers/vehicles/Versions"
  )
);

/**
 * DASHBOARD =========================
 */

const VehiclesDashboard = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesDashboardIndexComponent" */
    "../containers/vehicles/dashboard/Index"
  )
);

const VehiclesDashboardGlobalEvents = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesDashboardGlobalEventsComponent" */
    "../containers/vehicles/dashboard/Events"
  )
);

const VehiclesMultiDashboard = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesMultiDashboardComponent" */
    "../containers/vehicles/dashboard/Multi"
  )
);

/**
 * ITEMS =============================
 */

const VehiclesItemsIndex = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesItemsIndexComponent" */
    "../containers/vehicles/items/Index"
  )
);

const VehiclesShowItem = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesShowItemComponent" */
    "../containers/vehicles/items/Show"
  )
);

const VehiclesNewItem = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesNewItemComponent" */
    "../containers/vehicles/items/New"
  )
);

const VehiclesEditItem = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesItemsEditItemComponent" */
    "../containers/vehicles/items/Edit"
  )
);

// ITEMS TRANSFERS
const VehiclesItemsTransfersIndex = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesItemsTransfersIndexComponents" */
    "../containers/vehicles/itemsTransfers/List"
  )
);

/**
 * USERS =============================
 */

const VehiclesUsersList = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesUsersListComponent" */
    "../containers/vehicles/users/List"
  )
);

const VehiclesNewUser = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesNewUserComponent" */
    "../containers/vehicles/users/New"
  )
);

const VehiclesShowUser = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesNewShowComponent" */
    "../containers/vehicles/users/Show"
  )
);

/**
 * CONTACTS ==========================
 */

const VehiclesContactsIndex = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesContactIndexComponent" */
    "../containers/vehicles/contacts/Index"
  )
);

const VehiclesNewContact = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesNewContactComponent" */
    "../containers/vehicles/contacts/New"
  )
);

const VehiclesEditContact = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesEditContactComponent" */
    "../containers/vehicles/contacts/Edit"
  )
);

const VehiclesShowContact = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesShowContactComponent" */
    "../containers/vehicles/contacts/Show"
  )
);

/**
 * CONTACTS GROUPS ===================
 */

const VehiclesContactsGroupsIndex = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesContactIndexComponent" */
    "../containers/vehicles/contactsGroups/Index"
  )
);

const VehiclesShowContactsGroup = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesShowContactComponent" */
    "../containers/vehicles/contactsGroups/Show"
  )
);

/**
 * ORDERS ============================
 */

const VehiclesOrdersIndex = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesOrdersIndexComponent" */
    "../containers/vehicles/orders/Index"
  )
);

const VehiclesNewOrder = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesNewOrderComponent" */
    "../containers/vehicles/orders/New"
  )
);

const VehiclesEditOrder = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesEditOrderComponent" */
    "../containers/vehicles/orders/Edit"
  )
);

const VehiclesOrdersShow = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesShowOrderComponent" */
    "../containers/vehicles/orders/Show"
  )
);

const VehiclesOrdersPlanning = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesOrdersPlanning" */
    "../containers/vehicles/orders/Planning"
  )
);

const VehiclesOrdersRatings = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesOrdersRatings" */
    "../containers/vehicles/orders/Ratings"
  )
);

const VehiclesOrdersDeliveryJournal = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesOrdersDeliveryJournal" */
    "../containers/vehicles/orders/DeliveryJournal"
  )
);
/**
 * PLANNING ==========================
 */

const VehiclesPlanningIndex = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesPlanningIndexComponent" */
    "../containers/vehicles/planning/Index"
  )
);

/**
 * PRODUCTS ==========================
 */

const VehiclesProductsIndex = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesProductsIndexComponent" */
    "../containers/vehicles/products/Index"
  )
);

const VehiclesProductsNew = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesProductsNewComponent" */
    "../containers/vehicles/products/New"
  )
);

const VehiclesProductsShow = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesProductsShowComponent" */
    "../containers/vehicles/products/Show"
  )
);

/**
 * BILLING ===========================
 */

const VehiclesBillingIndex = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesBillingIndexComponent" */
    "../containers/vehicles/billing/Index"
  )
);

const VehiclesBillingGroupedInvoice = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesBillingGroupedInvoiceComponent" */
    "../containers/vehicles/billing/GroupedInvoice"
  )
);

/**
 * KBOX ==============================
 */

const VehiclesKboxsList = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesKboxsList" */
    "../containers/vehicles/kbox/List"
  )
);

const VehiclesShowKbox = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesShowKbox" */
    "../containers/vehicles/kbox/Show"
  )
);

const VehiclesNewKbox = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesNewKbox" */
    "../containers/vehicles/kbox/New"
  )
);

const VehiclesEditKbox = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesEditKbox" */
    "../containers/vehicles/kbox/Edit"
  )
);

/**
 * QUOTE ===============================
 */

const VehiclesQuotesIndex = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesQuotesIndex" */
    "../containers/vehicles/quotes/Index"
  )
);

const VehiclesQuotesNew = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesQuotesNew" */
    "../containers/vehicles/quotes/New"
  )
);

const VehiclesQuotesEdit = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesQuotesEdit" */
    "../containers/vehicles/quotes/Edit"
  )
);

const VehiclesQuotesShow = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesQuotesShow" */
    "../containers/vehicles/quotes/Show"
  )
);

/**
 * PIPELINES ===========================
 */

const VehiclesPipelinesIndex = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesPipelinesIndex" */
    "../containers/vehicles/pipelines/Index"
  )
);

const VehiclesPipelinesNew = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesPipelinesNew" */
    "../containers/vehicles/pipelines/New"
  )
);

const VehiclesPipelinesShow = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesPipelinesShow" */
    "../containers/vehicles/pipelines/Show"
  )
);

const VehiclesPipelinesBoard = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesPipelinesBoard" */
    "../containers/vehicles/pipelines/Board"
  )
);

/**
 * INVITATION ==========================
 */

const Invitation = lazy(() =>
  import(
    /* webpackChunkName: "InvitationComponent" */
    "../containers/vehicles/invitations/Invitation"
  )
);

/**
 * LEAVES ==============================
 */

const VehiclesLeavesList = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesLeavesListComponent" */
    "../containers/vehicles/leaves/List"
  )
);

const VehiclesLeavesShow = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesLeavesShowComponent" */
    "../containers/vehicles/leaves/Show"
  )
);

const VehiclesLeavesPlanning = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesLeavesPlanningComponent" */
    "../containers/vehicles/leaves/Planning"
  )
);

const VehiclesLeavesGlobalPlanning = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesLeavesPlanningComponent" */
    "../containers/vehicles/leaves/GlobalPlanning"
  )
);

/**
 * TASKS HISTORY =======================
 */

const VehiclesTasksHistoryList = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesTasksHistoryListComponent" */
    "../containers/vehicles/tasksHistory/List"
  )
);

/**
 * TASK DEFINITIONS ====================
 */

const VehiclesTaskDefinitionsList = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesTaskDefinitionsListComponent" */
    "../containers/vehicles/taskDefinitions/List"
  )
);

const VehiclesTaskDefinitionsShow = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesTaskDefinitionsShowComponent" */
    "../containers/vehicles/taskDefinitions/Show"
  )
);

/**
 * DAMAGE REQUETS ======================
 */

const VehiclesDamageRequestsList = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesDamageRequestsListComponent" */
    "../containers/vehicles/damageRequests/List"
  )
);

const VehiclesDamageRequestsNew = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesDamageRequestsNewComponent" */
    "../containers/vehicles/damageRequests/New"
  )
);

const VehiclesDamageRequestsShow = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesDamageRequestsShowComponent" */
    "../containers/vehicles/damageRequests/Show"
  )
);

/**
 *PROJECTED COSTS ======================
 */

const VehiclesProjectedCostsList = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesProjectedCostsListComponent" */
    "../containers/vehicles/projectedCosts/List"
  )
);

const VehiclesProjectedCostsShow = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesProjectedCostsShowComponent" */
    "../containers/vehicles/projectedCosts/Show"
  )
);

/**
 * SETTINGS ==========================
 */

// Settings
const VehiclesSettingsUserWorks = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesSettingsUserWorksComponent" */
    "../containers/vehicles/settings/UserWorks"
  )
);

const VehiclesSettingsOrderCategories = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesSettingsOrderCategoriesComponent" */
    "../containers/vehicles/settings/OrderCategories"
  )
);

const VehiclesSettingsOrderGroups = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesSettingsOrderGroupsComponent" */
    "../containers/vehicles/settings/OrderGroups"
  )
);

const VehiclesSettingsItemsSharings = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesSettingsItemsSharingsComponent" */
    "../containers/vehicles/settings/ItemsSharings"
  )
);

const VehiclesSettingsPipelineTemplates = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesSettingsItemsSharingsComponent" */
    "../containers/vehicles/settings/PipelineTemplates"
  )
);

const VehiclesSettingsNewPipelineTemplates = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesSettingsNewPipelineTemplates" */
    "../containers/vehicles/settings/new/NewPipelineTemplates"
  )
);

const VehiclesSettingsEditPipelineTemplates = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesSettingsEditPipelineTemplates" */
    "../containers/vehicles/settings/edit/EditPipelineTemplates"
  )
);

const VehiclesSettingsPipelinesSharings = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesSettingsPipelinesSharingsComponent" */
    "../containers/vehicles/settings/PipelinesSharings"
  )
);

const VehiclesSettingsFeedbackAlerts = lazy(() =>
  import(
    /* webpackChunkName: "VehiclesSettingsFeedbackAlerts" */
    "../containers/vehicles/settings/FeedbackAlerts"
  )
);

export default function Vehicles() {
  return (
    <Switch>
      {/* Invitation */}

      <Route
        exact
        path="/vehicles/invitations/:id"
        component={suspensify(Invitation, "pageLoading")}
      />

      <VehiclesLayout>
        <Switch>
          {/* Index */}
          <Route
            exact
            path="/vehicles"
            component={suspensify(VehiclesIndex, "pageLoading")}
          />

          {/* Versions */}
          <Route
            exact
            path="/vehicles/versions"
            component={suspensify(VehiclesVersions, "pageLoading")}
          />

          {/* Dashboard */}
          <Route
            exact
            path="/vehicles/dash"
            component={suspensify(VehiclesDashboard, "pageLoading")}
          />
          <Route
            exact
            path="/vehicles/dash/events"
            component={suspensify(VehiclesDashboardGlobalEvents, "pageLoading")}
          />

          <Route
            exact
            path="/vehicles/dash/multi"
            component={suspensify(VehiclesMultiDashboard, "pageLoading")}
          />

          {/* Items Transfers */}
          <Route
            exact
            path="/vehicles/items/transfers"
            component={suspensify(VehiclesItemsTransfersIndex, "pageLoading")}
          />

          {/* Items */}
          <Route
            exact
            path="/vehicles/items"
            component={suspensify(VehiclesItemsIndex, "pageLoading")}
          />
          <Route
            exact
            path="/vehicles/items/new"
            component={suspensify(VehiclesNewItem, "pageLoading")}
          />
          <Route
            exact
            path="/vehicles/items/:itemId"
            component={suspensify(VehiclesShowItem, "pageLoading")}
          />
          <Route
            exact
            path="/vehicles/items/:itemId/edit"
            component={suspensify(VehiclesEditItem, "pageLoading")}
          />

          {/* Users */}
          <Route
            exact
            path="/vehicles/users"
            component={suspensify(VehiclesUsersList, "pageLoading")}
          />
          <Route
            exact
            path="/vehicles/users/new"
            component={suspensify(VehiclesNewUser, "pageLoading")}
          />
          <Route
            exact
            path="/vehicles/users/:userName"
            component={suspensify(VehiclesShowUser, "pageLoading")}
          />

          {/* Contacts */}
          <Route
            exact
            path="/vehicles/contacts"
            component={suspensify(VehiclesContactsIndex, "pageLoading")}
          />
          <Route
            exact
            path="/vehicles/contacts/new"
            component={suspensify(VehiclesNewContact, "pageLoading")}
          />
          <Route
            exact
            path="/vehicles/contacts/:uid"
            component={suspensify(VehiclesShowContact, "pageLoading")}
          />
          <Route
            exact
            path="/vehicles/contacts/:uid/edit"
            component={suspensify(VehiclesEditContact, "pageLoading")}
          />

          {/* Contacts Groups */}
          <Route
            exact
            path="/vehicles/contacts-groups"
            component={suspensify(VehiclesContactsGroupsIndex, "pageLoading")}
          />
          <Route
            exact
            path="/vehicles/contacts-groups/:id"
            component={suspensify(VehiclesShowContactsGroup, "pageLoading")}
          />

          {/* Orders */}
          <Route
            exact
            path="/vehicles/orders"
            component={suspensify(VehiclesOrdersIndex, "pageLoading")}
          />
          <Route
            exact
            path="/vehicles/orders/new"
            component={suspensify(VehiclesNewOrder, "pageLoading")}
          />
          <Route
            exact
            path="/vehicles/orders/ratings"
            component={suspensify(VehiclesOrdersRatings, "pageLoading")}
          />
          <Route
            exact
            path="/vehicles/orders/planning"
            component={suspensify(VehiclesOrdersPlanning, "pageLoading")}
          />
          <Route
            exact
            path="/vehicles/orders/delivery-journal"
            component={suspensify(VehiclesOrdersDeliveryJournal, "pageLoading")}
          />
          <Route
            exact
            path="/vehicles/orders/:id"
            component={suspensify(VehiclesOrdersShow, "pageLoading")}
          />
          <Route
            exact
            path="/vehicles/orders/:id/edit"
            component={suspensify(VehiclesEditOrder, "pageLoading")}
          />
          {/* Planning */}
          <Route
            exact
            path="/vehicles/planning"
            component={suspensify(VehiclesPlanningIndex, "pageLoading")}
          />
          {/* Products */}
          <Route
            exact
            path="/vehicles/products"
            component={suspensify(VehiclesProductsIndex, "pageLoading")}
          />
          <Route
            exact
            path="/vehicles/products/new"
            component={suspensify(VehiclesProductsNew, "pageLoading")}
          />
          <Route
            exact
            path="/vehicles/products/:id"
            component={suspensify(VehiclesProductsShow, "pageLoading")}
          />
          {/* Billing */}
          <Route
            exact
            path="/vehicles/billing"
            component={suspensify(VehiclesBillingIndex, "pageLoading")}
          />
          <Route
            exact
            path="/vehicles/billing/groupedInvoice"
            component={suspensify(VehiclesBillingGroupedInvoice, "pageLoading")}
          />
          {/* Kbox */}
          <Route
            exact
            path="/vehicles/kbox"
            component={suspensify(VehiclesKboxsList, "pageLoading")}
          />
          <Route
            exact
            path="/vehicles/kbox/new"
            component={suspensify(VehiclesNewKbox, "pageLoading")}
          />
          <Route
            exact
            path="/vehicles/kbox/:id"
            component={suspensify(VehiclesShowKbox, "pageLoading")}
          />
          <Route
            exact
            path="/vehicles/kbox/:id/edit"
            component={suspensify(VehiclesEditKbox, "pageLoading")}
          />

          {/* Quote */}
          <Route
            exact
            path="/vehicles/quotes"
            component={suspensify(VehiclesQuotesIndex, "pageLoading")}
          />

          <Route
            exact
            path="/vehicles/quotes/new"
            component={suspensify(VehiclesQuotesNew, "pageLoading")}
          />

          <Route
            exact
            path="/vehicles/quotes/:id"
            component={suspensify(VehiclesQuotesShow, "pageLoading")}
          />

          <Route
            exact
            path="/vehicles/quotes/:id/edit"
            component={suspensify(VehiclesQuotesEdit, "pageLoading")}
          />

          {/* Pipelines */}
          <Route
            exact
            path="/vehicles/pipelines"
            component={suspensify(VehiclesPipelinesIndex, "pageLoading")}
          />

          <Route
            exact
            path="/vehicles/pipelines/new"
            component={suspensify(VehiclesPipelinesNew, "pageLoading")}
          />

          <Route
            exact
            path="/vehicles/pipelines/board"
            component={suspensify(VehiclesPipelinesBoard, "pageLoading")}
          />

          <Route
            exact
            path="/vehicles/pipelines/:id"
            component={suspensify(VehiclesPipelinesShow, "pageLoading")}
          />

          {/* Leaves */}
          <Route
            exact
            path="/vehicles/leaves"
            component={suspensify(VehiclesLeavesList, "pageLoading")}
          />

          <Route
            exact
            path="/vehicles/leaves/planning"
            component={suspensify(VehiclesLeavesPlanning, "pageLoading")}
          />

          <Route
            exact
            path="/vehicles/leaves/global-planning"
            component={suspensify(VehiclesLeavesGlobalPlanning, "pageLoading")}
          />

          <Route
            exact
            path="/vehicles/leaves/:id"
            component={suspensify(VehiclesLeavesShow, "pageLoading")}
          />

          {/* Lates */}
          {/* <Route
            exact
            path="/vehicles/lates"
            component={suspensify(VehiclesLatesList, "pageLoading")}
          />

          <Route
            exact
            path="/vehicles/lates/:id"
            component={suspensify(VehiclesLatesShow, "pageLoading")}
          /> */}

          {/* Tasks History */}

          <Route
            exact
            path="/vehicles/tasks/history"
            component={suspensify(VehiclesTasksHistoryList, "pageLoading")}
          />

          {/* Task Definitions */}
          <Route
            exact
            path="/vehicles/tasks"
            component={suspensify(VehiclesTaskDefinitionsList, "pageLoading")}
          />

          <Route
            exact
            path="/vehicles/tasks/:id"
            component={suspensify(VehiclesTaskDefinitionsShow, "pageLoading")}
          />

          {/* Damage Requests */}
          <Route
            exact
            path="/vehicles/damage-requests"
            component={suspensify(VehiclesDamageRequestsList, "pageLoading")}
          />

          <Route
            exact
            path="/vehicles/damage-requests/new"
            component={suspensify(VehiclesDamageRequestsNew, "pageLoading")}
          />

          <Route
            exact
            path="/vehicles/damage-requests/:id"
            component={suspensify(VehiclesDamageRequestsShow, "pageLoading")}
          />

          {/* Projected Costs */}
          <Route
            exact
            path="/vehicles/projected-costs"
            component={suspensify(VehiclesProjectedCostsList, "pageLoading")}
          />

          <Route
            exact
            path="/vehicles/projected-costs/:id"
            component={suspensify(VehiclesProjectedCostsShow, "pageLoading")}
          />

          {/* Settings */}
          <VehiclesSettingsLayout>
            <Route
              exact
              path="/vehicles/settings"
              component={suspensify(
                VehiclesSettingsOrderCategories,
                "pageLoading"
              )}
            />

            <Route
              exact
              path="/vehicles/settings/orderGroups"
              component={suspensify(VehiclesSettingsOrderGroups, "pageLoading")}
            />

            <Route
              exact
              path="/vehicles/settings/userWorks"
              component={suspensify(VehiclesSettingsUserWorks, "pageLoading")}
            />

            <Route
              exact
              path="/vehicles/settings/pipelineTemplates"
              component={suspensify(
                VehiclesSettingsPipelineTemplates,
                "pageLoading"
              )}
            />

            <Route
              exact
              path="/vehicles/settings/pipelineTemplates/new"
              component={suspensify(
                VehiclesSettingsNewPipelineTemplates,
                "pageLoading"
              )}
            />

            <Route
              exact
              path="/vehicles/settings/pipelineTemplates/edit"
              component={suspensify(
                VehiclesSettingsEditPipelineTemplates,
                "pageLoading"
              )}
            />

            <Route
              exact
              path="/vehicles/settings/itemsSharings"
              component={suspensify(
                VehiclesSettingsItemsSharings,
                "pageLoading"
              )}
            />

            <Route
              exact
              path="/vehicles/settings/pipelinesSharings"
              component={suspensify(
                VehiclesSettingsPipelinesSharings,
                "pageLoading"
              )}
            />

            <Route
              exact
              path="/vehicles/settings/feedbackAlerts"
              component={suspensify(
                VehiclesSettingsFeedbackAlerts,
                "pageLoading"
              )}
            />
          </VehiclesSettingsLayout>
        </Switch>
      </VehiclesLayout>
    </Switch>
  );
}
