import createReducer from "../../lib/createReducer";
import * as types from "../../actions/types";

const initialState = {
  vehicles: null,
};

export default createReducer(initialState, {
  [types.ACCOUNT_SET_APP_SETTINGS](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    const { appName, appSettings } = action.state;
    newState[appName] = appSettings;

    return newState;
  },
});
