import createReducer from "../lib/createReducer";
import * as types from "../actions/types";

const initialState = {
  online: true,
  menuCollapsed: false,
};

export default createReducer(initialState, {
  [types.APP_SET_ONLINE_STATE](state, action) {
    const { online } = action.state;
    const newState = { ...state, online };
    return newState;
  },

  [types.APP_SET_MENU_COLLAPSED](state, action) {
    const { collapsed } = action.state;
    const newState = { ...state, menuCollapsed: collapsed };
    return newState;
  },
});
