import createReducer from "../../lib/createReducer";
import * as types from "../../actions/types";

const initialState = {
  items: [],
  totalCount: 0,
  item: null,
};

export default createReducer(initialState, {
  [types.VEHICLES_LEAVES_SET_LEAVES](state, action) {
    const newState = Object.assign({}, state, { items: action.state.items });

    return newState;
  },

  [types.VEHICLES_LEAVES_SET_LEAVES_TOTALCOUNT](state, action) {
    const newState = Object.assign({}, state, {
      totalCount: action.state.totalCount,
    });

    return newState;
  },

  [types.VEHICLES_LEAVES_SET_LEAVE](state, action) {
    const newState = Object.assign({}, state, { item: action.state.item });

    return newState;
  },

  [types.VEHICLES_LEAVES_SET_CREATED_LEAVE](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { item } = action.state;

    newState.items.unshift(item);

    return newState;
  },

  [types.VEHICLES_LEAVES_SET_UPDATED_LEAVE](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    newState.items = newState.items.map((l) => {
      if (l.id === action.state.leaveId) {
        l = action.state.item;
        return l;
      }
      return l;
    });

    if (newState.item && newState.item.id === action.state.leaveId) {
      if (action.state.item.late) {
        action.state.item.late /= 60000000000;
      }

      newState.item = action.state.item;
    }

    return newState;
  },

  [types.VEHICLES_LEAVES_SET_DELETED_LEAVE](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    const { leaveId } = action.state;
    newState.items = newState.items.filter((i) => i.id !== leaveId);

    return newState;
  },

  [types.VEHICLES_LEAVES_SET_ACCEPTED_LEAVE](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    newState.items = newState.items.map((l) => {
      if (l.id === action.state.leaveId) {
        l.state = 5;
        return l;
      }
      return l;
    });

    if (newState.item && newState.item.id === action.state.leaveId) {
      newState.item.state = 5;
    }

    return newState;
  },

  [types.VEHICLES_LEAVES_SET_REFUSED_LEAVE](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    newState.items = newState.items.map((l) => {
      if (l.id === action.state.leaveId) {
        l.state = 10;
        return l;
      }
      return l;
    });

    if (newState.item && newState.item.id === action.state.leaveId) {
      newState.item.state = 10;
    }

    return newState;
  },
});
