import createReducer from "../../lib/createReducer";
import * as types from "../../actions/types";
import moment from "moment";

const initialState = {
  item: null,
  itemTasks: [],
  items: {
    all: [],
    invoiced: [],
    done: [],
    delivered: [],
    waiting: [],
    accepted: [],
    discarded: [],
  },
  pagesKeys: [],
  count: {
    all: null,
  },
  totalCount: {
    all: null,
  },
  currentDayIncomes: 0,
  currentWeekIncomes: 0,
  currentMonthIncomes: 0,

  feedback: null,

  userComments: null,
};

export default createReducer(initialState, {
  // ORDER =========================================================================================

  [types.VEHICLES_SET_ORDER](state, action) {
    const newState = Object.assign({}, state, { item: action.state.item });
    return newState;
  },

  // ORDERS ========================================================================================

  [types.VEHICLES_SET_ORDERS](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    const stateFilter = action.state.stateFilter || "all";
    newState.items[stateFilter] = action.state.items;

    return newState;
  },

  [types.VEHICLES_SET_UPDATED_ORDER_CONTACT_NAME](state, action) {
    const { orderId, firstName, lastName } = action.state;
    const newState = JSON.parse(JSON.stringify(state));

    // Set current order as accepted
    if (newState.item && newState.item.id === orderId) {
      newState.item.contact.data.firstName = firstName;
      newState.item.contact.data.lastName = lastName;
    }

    return newState;
  },

  // PAGESKEYS =====================================================================================
  [types.VEHICLES_SET_ORDER_PAGEKEYS_NULL](state, _) {
    const newState = JSON.parse(JSON.stringify(state));

    newState.pagesKeys = [];

    return newState;
  },

  [types.VEHICLES_SET_ORDER_PAGEKEYS](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    newState.pagesKeys[action.state.page] = action.state.baseKey;

    return newState;
  },

  // COUNTS ========================================================================================

  [types.VEHICLES_SET_ORDERS_COUNT](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    const stateFilter = action.state.stateFilter || "all";
    newState.count[stateFilter] = action.state.count;

    return newState;
  },

  [types.VEHICLES_SET_ORDERS_TOTAL_COUNT](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    const stateFilter = action.state.stateFilter || "all";
    newState.totalCount[stateFilter] = action.state.totalCount;

    return newState;
  },

  [types.VEHICLES_INCREMENT_ORDERS_TOTAL_COUNT](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    const stateFilter = action.state.stateFilter || "all";
    let cnt = newState.totalCount[stateFilter] || 0;
    cnt += 1;
    newState.totalCount[stateFilter] = cnt;

    return newState;
  },

  [types.VEHICLES_DECREMENT_ORDERS_TOTAL_COUNT](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    const stateFilter = action.state.stateFilter || "all";
    let cnt = newState.totalCount[stateFilter] || 1;
    cnt -= 1;
    newState.totalCount[stateFilter] = cnt;

    return newState;
  },

  // CHANGE STATES =================================================================================

  [types.VEHICLES_SET_ORDER_ACQUISITION_DONE](state, action) {
    const { orderId } = action.state;
    const newState = JSON.parse(JSON.stringify(state));

    // Set current order acquisition done
    if (newState.item && newState.item.id === orderId) {
      newState.item.acquisitionDone = true;
    }

    return newState;
  },

  [types.VEHICLES_SET_ORDER_ACCEPTED](state, action) {
    const { orderId } = action.state;
    const newState = JSON.parse(JSON.stringify(state));

    // Set current order as accepted
    if (newState.item && newState.item.id === orderId) {
      newState.item.state = 10;
    }

    // Get order in accepted orders
    let order = null;
    newState.items.waiting.forEach((o) => {
      if (o.id === orderId) {
        order = o;
      }
    });

    if (order) {
      order.state = 10;

      // Remove order from waiting list
      newState.items.waiting = newState.items.waiting.filter(
        (o) => o.id !== orderId
      );

      // Add order in accepted list
      newState.items.accepted = newState.items.accepted || [];
      newState.items.accepted.unshift(order);

      // Decrement waiting total count
      newState.totalCount.waiting = newState.totalCount.waiting || 1;
      newState.totalCount.waiting -= 1;

      // Increment accepted total count
      newState.totalCount.accepted = newState.totalCount.accepted || 0;
      newState.totalCount.accepted += 1;
    }

    return newState;
  },

  [types.VEHICLES_SET_ORDER_DECLINED](state, action) {
    const { orderId } = action.state;
    const newState = JSON.parse(JSON.stringify(state));

    // Set current order as declined
    if (newState.item && newState.item.id === orderId) {
      newState.item.state = 5;
    }

    // Get order in waiting / accepted orders
    let order = null;
    newState.items.waiting.forEach((o) => {
      if (o.id === orderId) {
        order = o;

        // Increment waiting total count
        newState.totalCount.waiting = newState.totalCount.waiting || 1;
        newState.totalCount.waiting -= 1;
      }
    });

    newState.items.accepted.forEach((o) => {
      if (o.id === orderId) {
        order = o;

        // Decrement accepted total count
        newState.totalCount.accepted = newState.totalCount.accepted || 1;
        newState.totalCount.accepted -= 1;
      }
    });

    if (order) {
      order.state = 5;

      // Remove order from waiting / accepted list
      newState.items.waiting = newState.items.waiting.filter(
        (o) => o.id !== orderId
      );
      newState.items.accepted = newState.items.accepted.filter(
        (o) => o.id !== orderId
      );

      // Add order in declined list
      newState.items.discarded = newState.items.discarded || [];
      newState.items.discarded.unshift(order);

      // Increment accepted total count
      newState.totalCount.discarded = newState.totalCount.discarded || 0;
      newState.totalCount.discarded += 1;
    }

    return newState;
  },

  [types.VEHICLES_SET_ORDER_DONE](state, action) {
    const { orderId } = action.state;
    const newState = JSON.parse(JSON.stringify(state));

    // Set current order as done
    if (newState.item && newState.item.id === orderId) {
      newState.item.state = 15;
    }

    // Get order in accepted orders
    let order = null;
    newState.items.accepted.forEach((o) => {
      if (o.id === orderId) {
        order = o;
      }
    });

    if (order) {
      order.state = 15;

      // Remove order from accepted list
      newState.items.accepted = newState.items.accepted.filter(
        (o) => o.id !== orderId
      );

      // Add order in done list
      newState.items.done = newState.items.done || [];
      newState.items.done.unshift(order);

      // Decrement accepted total count
      newState.totalCount.accepted = newState.totalCount.accepted || 1;
      newState.totalCount.accepted -= 1;

      // Increment done total count
      newState.totalCount.done = newState.totalCount.done || 0;
      newState.totalCount.done += 1;
    }

    return newState;
  },

  [types.VEHICLES_SET_ORDER_DELIVERED](state, action) {
    const { orderId } = action.state;
    const newState = JSON.parse(JSON.stringify(state));

    // Set current order as done
    if (newState.item && newState.item.id === orderId) {
      newState.item.state = 17;
    }

    // Get order in done orders
    let order = null;
    newState.items.done.forEach((o) => {
      if (o.id === orderId) {
        order = o;
      }
    });

    if (order) {
      order.state = 17;

      // Remove order from accepted list
      newState.items.done = newState.items.done.filter((o) => o.id !== orderId);

      // Add order in delivered list
      newState.items.delivered = newState.items.delivered || [];
      newState.items.delivered.unshift(order);

      // Decrement done total count
      newState.totalCount.done = newState.totalCount.done || 1;
      newState.totalCount.accepted -= 1;

      // Increment delivered total count
      newState.totalCount.delivered = newState.totalCount.delivered || 0;
      newState.totalCount.delivered += 1;
    }

    return newState;
  },

  [types.VEHICLES_SET_ORDER_INVOICED](state, action) {
    const { orderId } = action.state;
    const newState = JSON.parse(JSON.stringify(state));

    // Set current order as invoiced
    if (newState.item && newState.item.id === orderId) {
      newState.item.state = 20;
    }

    // Get order in done orders
    let order = null;
    newState.items.done.forEach((o) => {
      if (o.id === orderId) {
        order = o;
      }
    });

    if (order) {
      order.state = 20;

      // Remove order from done list
      newState.items.done = newState.items.done.filter((o) => o.id !== orderId);

      // Add order in invoiced list
      newState.items.invoiced = newState.items.invoiced || [];
      newState.items.invoiced.unshift(order);

      // Decrement done total count
      newState.totalCount.done = newState.totalCount.done || 1;
      newState.totalCount.done -= 1;

      // Increment invoiced total count
      newState.totalCount.invoiced = newState.totalCount.invoiced || 0;
      newState.totalCount.invoiced += 1;
    }

    return newState;
  },

  // ORDER TASKS ===================================================================================

  [types.VEHICLES_SET_ORDER_SETTED_TASK](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    const { orderId, taskData } = action.state;

    if (newState.item.id === orderId) {
      newState.itemTasks.push(taskData);
    }

    return newState;
  },

  [types.VEHICLES_SET_ORDER_UNSETTED_TASK](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    const { orderId, taskId } = action.state;

    if (newState.item.id === orderId) {
      newState.itemTasks = newState.itemTasks.filter((t) => t.id !== taskId);
    }

    return newState;
  },

  [types.VEHICLES_SET_ORDER_TASKS](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { items } = action.state;

    Object.assign(newState, {
      itemTasks: items,
    });

    return newState;
  },

  [types.VEHICLES_ORDERS_SET_USER_ASSIGNED_IN_TASK](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { user, orderId, taskId } = action.state;

    newState.itemTasks.forEach((t) => {
      if (t.orderId === orderId && t.id === taskId) {
        Object.assign(t, { user });
      }
    });

    return newState;
  },

  [types.VEHICLES_SET_ORDER_TASK_DONE](state, action) {
    const { taskId } = action.state;
    const newState = JSON.parse(JSON.stringify(state));

    newState.itemTasks.forEach((t) => {
      if (t.id === taskId) {
        Object.assign(t, { state: 10 });
      }
    });

    return newState;
  },

  [types.VEHICLES_SET_ORDER_TASK_RUN](state, action) {
    const { taskId } = action.state;
    const newState = JSON.parse(JSON.stringify(state));

    newState.itemTasks.forEach((t) => {
      if (t.id === taskId) {
        Object.assign(t, { state: 5 });
      }
    });

    return newState;
  },

  [types.VEHICLES_SET_ORDER_TASK_PAUSE](state, action) {
    const { taskId } = action.state;
    const newState = JSON.parse(JSON.stringify(state));

    newState.itemTasks.forEach((t) => {
      if (t.id === taskId) {
        Object.assign(t, { state: 0 });
      }
    });

    return newState;
  },

  [types.VEHICLES_SET_ORDER_TASK_DATES](state, action) {
    const { taskId, task } = action.state;
    const newState = JSON.parse(JSON.stringify(state));

    newState.itemTasks.forEach((t) => {
      if (t.id === taskId) {
        Object.assign(t, { ...task });
      }
    });

    return newState;
  },

  [types.VEHICLES_SET_ORDER_TASK_INTERNAL_RATE](state, action) {
    const { taskId, rate } = action.state;
    const newState = JSON.parse(JSON.stringify(state));

    newState.itemTasks.forEach((t) => {
      if (t.id === taskId) {
        t.internalRate = rate;
      }
    });

    return newState;
  },

  // INCOMES =======================================================================================

  [types.VEHICLES_SET_ORDER_CURRENT_DAY_INCOMES](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { incomes } = action.state;

    Object.assign(newState, {
      currentDayIncomes: incomes,
    });

    return newState;
  },

  [types.VEHICLES_SET_ORDER_CURRENT_WEEK_INCOMES](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { incomes } = action.state;

    Object.assign(newState, {
      currentWeekIncomes: incomes,
    });

    return newState;
  },

  [types.VEHICLES_SET_ORDER_CURRENT_MONTH_INCOMES](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { incomes } = action.state;

    Object.assign(newState, {
      currentMonthIncomes: incomes,
    });

    return newState;
  },

  // FEEDBACKS =====================================================================================

  [types.VEHICLES_SET_ORDER_FEEDBACK](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    const { feedback } = action.state;
    newState.feedback = feedback;

    return newState;
  },

  [types.VEHICLES_SET_CREATED_ORDER_FEEDBACK](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    const { feedback, orderId } = action.state;
    newState.createdFeedback = feedback;

    if (newState.item && newState.item.id === orderId) {
      newState.item.feedback = feedback;
    }

    newState.feedback = feedback;
    newState.feedback.lockedAt = moment()
      .subtract(1, "minutes")
      .toDate()
      .toISOString();

    return newState;
  },

  [types.VEHICLES_SET_CREATED_ORDER_FEEDBACK_COMMENT](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    const { comment, orderId } = action.state;
    newState.createdFeedbackComment = comment;

    if (newState.item && newState.item.id === orderId) {
      if (newState.item.feedback) {
        newState.item.feedback.comments = newState.item.feedback.comments || [];
        newState.item.feedback.comments.push(comment);
      }
    }

    if (newState.feedback && newState.feedback.comments) {
      newState.feedback.comments = newState.feedback.comments || [];
      newState.feedback.comments.push(comment);
    }

    return newState;
  },

  // USER COMMENT ==================================================================================

  [types.VEHICLES_SET_USER_COMMENTS](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    const { comment } = action.state;
    newState.userComments = comment;

    return newState;
  },

  [types.VEHICLES_SET_CREATED_USER_COMMENT](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    const { comment } = action.state;

    if (newState.userComments) {
      newState.userComments = newState.userComments || [];
      newState.userComments.push(comment);
    }

    return newState;
  },

  // MANAGERS ======================================================================================

  [types.VEHICLES_SET_CREATED_ORDER_MANAGER](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    const { user } = action.state;
    if (!newState.item.managers) {
      newState.item.managers = [];
    }
    newState.item.managers.unshift(user);

    return newState;
  },

  [types.VEHICLES_SET_DELETED_ORDER_MANAGER](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { userName } = action.state;

    newState.item.managers = newState.item.managers.filter(
      (user) => user.userName !== userName
    );

    return newState;
  },
  // REFERRERS =====================================================================================

  [types.VEHICLES_SET_CREATED_ORDER_REFERRERS](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    const { user } = action.state;
    if (!newState.item.referrers) {
      newState.item.referrers = [];
    }
    newState.item.referrers.unshift(user);

    return newState;
  },

  [types.VEHICLES_SET_DELETED_ORDER_REFERRERS](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    const { userName } = action.state;
    newState.item.referrers = newState.item.referrers.filter(
      (user) => user.userName !== userName
    );

    return newState;
  },

  // CONTROLLER ==================================================================================

  [types.VEHICLES_SET_CREATED_ORDER_CONTROLLER](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    const { user } = action.state;
    newState.item.controller = user;
    newState.item.internalRate = 5;

    return newState;
  },

  [types.VEHICLES_ORDERS_UPDATE_INTERNAL_RATE](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    const { rate } = action.state;
    newState.item.internalRate = rate;

    return newState;
  },
});
