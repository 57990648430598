import createReducer from "../../lib/createReducer";
import * as types from "../../actions/types";

const initialState = {
  items: [],
  totalCount: 0,
  item: null,
};

export default createReducer(initialState, {
  [types.VEHICLES_PROJECTED_COSTS_SET_PROJECTED_COSTS](state, action) {
    const newState = Object.assign({}, state, { items: action.state.items });

    return newState;
  },

  [types.VEHICLES_PROJECTED_COSTS_SET_PROJECTED_COSTS_TOTALCOUNT](
    state,
    action
  ) {
    const newState = Object.assign({}, state, {
      totalCount: action.state.totalCount,
    });

    return newState;
  },

  [types.VEHICLES_PROJECTED_COSTS_SET_PROJECTED_COST](state, action) {
    const newState = Object.assign({}, state, { item: action.state.item });

    return newState;
  },

  [types.VEHICLES_PROJECTED_COSTS_SET_CREATED_PROJECTED_COST](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { item } = action.state;

    newState.items.unshift(item);

    return newState;
  },

  [types.VEHICLES_PROJECTED_COSTS_SET_UPDATED_PROJECTED_COST](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    newState.items = newState.items.map((l) => {
      if (l.id === action.state.projCostId) {
        l = action.state.item;
        return l;
      }
      return l;
    });

    if (newState.item && newState.item.id === action.state.projCostId) {
      newState.item = action.state.item;
    }

    return newState;
  },

  [types.VEHICLES_PROJECTED_COSTS_SET_DELETED_PROJECTED_COST](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    const { projCostId } = action.state;
    newState.items = newState.items.filter((i) => i.id !== projCostId);

    return newState;
  },
});
