import React, { Component } from "react";

import { Typography } from "antd";
import { IeOutlined, DownloadOutlined } from "@ant-design/icons";

const { Title, Paragraph } = Typography;

export default class BrowserNotSupported extends Component {
  render() {
    return (
      <div className="fh ce">
        <div style={{ textAlign: "center" }}>
          <IeOutlined style={{ fontSize: "100px" }} />

          <Title>Votre navigateur ne supporte pas Keematic.</Title>

          <Paragraph>
            <DownloadOutlined style={{ fontSize: "20px" }} /> Veuillez utiliser
            un navigateur plus récent tel que Microsoft Edge, Mozilla Firefox,
            Google Chrome, ...
          </Paragraph>
        </div>
      </div>
    );
  }
}
