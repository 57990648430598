import createReducer from "../../lib/createReducer";
import * as types from "../../actions/types";

const initialState = {
  item: null,
  items: [],

  totalCount: 0,
};

export default createReducer(initialState, {
  [types.VEHICLES_DAMAGE_REQUESTS_SET_REQUESTS](state, action) {
    const newState = Object.assign({}, state, { items: action.state.items });

    return newState;
  },

  [types.VEHICLES_DAMAGE_REQUESTS_SET_TOTALCOUNT](state, action) {
    const newState = Object.assign({}, state, {
      totalCount: action.state.totalCount,
    });

    return newState;
  },

  [types.VEHICLES_DAMAGE_REQUESTS_SET_REQUEST](state, action) {
    const newState = Object.assign({}, state, { item: action.state.item });

    return newState;
  },

  [types.VEHICLES_DAMAGE_REQUESTS_SET_REQUEST_FOR_SUPPORT](state, action) {
    const { requestId } = action.state;
    const newState = JSON.parse(JSON.stringify(state));

    // Set new state on current request
    if (newState.item && newState.item.id === requestId) {
      newState.item.state = 5;
    }

    return newState;
  },

  [types.VEHICLES_DAMAGE_REQUESTS_SET_ACCEPTED_REQUEST](state, action) {
    const { requestId } = action.state;
    const newState = JSON.parse(JSON.stringify(state));

    // Set new state on current request
    if (newState.item && newState.item.id === requestId) {
      newState.item.state = 10;
    }

    return newState;
  },

  [types.VEHICLES_DAMAGE_REQUESTS_SET_REFUSED_REQUEST](state, action) {
    const { requestId, refusedReason } = action.state;
    const newState = JSON.parse(JSON.stringify(state));

    // Set new state on current request
    if (newState.item && newState.item.id === requestId) {
      newState.item.state = 15;
      newState.item.refusedReason = refusedReason;
    }

    return newState;
  },
});
