import createReducer from "../../lib/createReducer";
import * as types from "../../actions/types";

const initialState = {
  items: [],
  item: null,
  createdItem: null,
  deletedItemId: null,

  completionTimes: null,
};

export default createReducer(initialState, {
  [types.VEHICLES_SET_TASK_DEFINITIONS](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    newState.items = action.state.items;

    return newState;
  },

  [types.VEHICLES_SET_TASK_DEFINITION](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    newState.item = action.state.item;

    return newState;
  },

  [types.VEHICLES_SET_CREATED_TASK_DEFINITION](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    newState.createdItem = action.state.item;
    newState.items.unshift(newState.createdItem);

    return newState;
  },

  [types.VEHICLES_SET_DELETED_TASK_DEFINITION](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    const { itemId } = action.state;
    newState.deletedItemId = itemId;
    newState.items = newState.items.filter((i) => i.id !== itemId);

    return newState;
  },

  [types.VEHICLES_SET_UPDATED_TASK_DEFINITION](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    newState.items = newState.items.map((itm) => {
      if (itm.id === action.state.itemId) {
        Object.assign(itm, {
          name: action.state.item.name,
          description: action.state.item.description,
          helpLink: action.state.item.helpLink,
          defaultTime: action.state.item.defaultTime / 60000000000, // convert to minutes
          userWorkName: action.state.item.userWork,
        });
      }

      return itm;
    });

    if (newState.item && newState.item.id === action.state.itemId) {
      Object.assign(newState.item, {
        name: action.state.item.name,
        description: action.state.item.description,
        helpLink: action.state.item.helpLink,
        defaultTime: action.state.item.defaultTime / 60000000000, // convert to minutes
        userWorkName: action.state.item.userWork,
        userWorkCost: null,
      });
    }

    return newState;
  },

  [types.VEHICLES_SET_TASK_DEFINITION_COMPLETION_TIMES](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    newState.completionTimes = action.state.item;

    return newState;
  },
});
