import * as Sentry from "@sentry/browser";
import { Auth } from "aws-amplify";
import * as types from "../types";
import { configurePubSub } from "../../boot/amplify";
import { useHistory } from "react-router-dom";
import api from "../../boot/api";

export function setCurrentUser(currentUser) {
  // Get device key
  const storageDeviceKey = [
    currentUser.keyPrefix,
    currentUser.username,
    "deviceKey",
  ].join(".");
  const deviceKey = currentUser.storage.getItem(storageDeviceKey);

  // Set device key as client id for iot pubsub
  configurePubSub(deviceKey);

  // Set sentry user
  Sentry.configureScope((scope) => {
    scope.setUser(currentUser);
  });

  return {
    type: types.ACCOUNT_SET_CURRENT_USER,
    state: { currentUser },
  };
}

export function getCurrentUser(bypassCache = true) {
  return (dispatch) =>
    Auth.currentAuthenticatedUser({ bypassCache }).then((user) => {
      if (!user.attributes) {
        const history = useHistory();
        Auth.signOut()
          .then(() => history.push("/auth/login"))
          .catch((err) => {
            console.error(err);
            history.push("/auth/login");
          });
        return null;
      }
      dispatch(setCurrentUser(user));
      return user;
    });
}

export function updateCurrentUserAttributes(attrs = {}) {
  return (dispatch) =>
    api.account
      .patch("/user-attributes", attrs)
      .then(() => dispatch(getCurrentUser(true)))
      .then((response) => response.data);
}
