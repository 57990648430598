import createReducer from "../../lib/createReducer";
import * as types from "../../actions/types";

const initialState = {
  items: [],
  item: null,
  selectedForUnlock: [],
  unlockDrawerOpen: false,
  count: 0,
  totalCount: 0,
  nextToken: null,
  deletedItemId: null,
};

export default createReducer(initialState, {
  [types.VEHICLES_SET_ITEMS](state, action) {
    const newState = Object.assign({}, state, { items: action.state.items });
    return newState;
  },

  [types.VEHICLES_SET_ITEMS_COUNT](state, action) {
    const newState = Object.assign({}, state, { count: action.state.count });
    return newState;
  },

  [types.VEHICLES_SET_ITEMS_TOTAL_COUNT](state, action) {
    const newState = Object.assign({}, state, {
      totalCount: action.state.totalCount,
    });
    return newState;
  },

  [types.VEHICLES_SET_ITEMS_NEXT_TOKEN](state, action) {
    const newState = Object.assign({}, state, {
      nextToken: action.state.nextToken,
    });
    return newState;
  },

  [types.VEHICLES_SET_ITEM](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    newState.item = action.state.item;
    return newState;
  },

  [types.VEHICLES_SET_ITEM_ARCHIVED](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { itemId } = action.state;

    if (newState.item && newState.item.id === itemId) {
      newState.item.archived = true;
    }

    newState.items.map((i) => {
      if (i.id === itemId) {
        i.archived = true;
      }

      return i;
    });

    return newState;
  },

  [types.VEHICLES_SET_ITEM_UNARCHIVED](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { itemId } = action.state;

    if (newState.item && newState.item.id === itemId) {
      newState.item.archived = false;
    }

    newState.items.map((i) => {
      if (i.id === itemId) {
        i.archived = false;
      }

      return i;
    });

    return newState;
  },

  [types.VEHICLES_ADD_ITEM_FOR_UNLOCK](state, action) {
    const newState = Object.assign({}, state);
    newState.selectedForUnlock.push(action.state.item);
    return newState;
  },

  [types.VEHICLES_CLEAR_ITEMS_FOR_UNLOCK](state) {
    const newState = Object.assign({}, state, { selectedForUnlock: [] });
    return newState;
  },

  [types.VEHICLES_REMOVE_ITEM_FOR_UNLOCK](state, action) {
    const newState = Object.assign({}, state);
    const { item } = action.state;

    let i = null;

    newState.selectedForUnlock.forEach((a, b) => {
      if (a.id === item.id) {
        i = b;
      }
    });

    newState.selectedForUnlock.splice(i, 1);

    if (newState.selectedForUnlock.length === 0) {
      newState.unlockDrawerOpen = false;
    }

    return newState;
  },

  [types.VEHICLES_TOGGLE_UNLOCK_DRAWER](state) {
    const newState = Object.assign({}, state, {
      unlockDrawerOpen: !state.unlockDrawerOpen,
    });
    return newState;
  },

  [types.VEHICLES_SET_DELETED_ITEM](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    newState.deletedItemId = action.state.itemId;
    newState.items = newState.items.filter(
      (i) => i.id !== newState.deletedItemId
    );

    return newState;
  },

  [types.VEHICLES_SET_CORE_STATE_ITEM](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    if (newState.item) {
      newState.item.coreState = action.state.coreState;
      newState.item.user = action.state.user;
    }

    newState.items.forEach((item, _) => {
      if (item.id === action.state.itemId) {
        item.coreState = action.state.coreState;
      }
    });

    return newState;
  },
});
