import createReducer from "../../lib/createReducer";
import * as types from "../../actions/types";

const initialState = {
  items: [],
  item: null,
  count: 0,
  totalCount: 0,
  createdItem: null,
  updatedItem: null,

  completionTimes: null,
};

export default createReducer(initialState, {
  [types.VEHICLES_SET_PRODUCTS](state, action) {
    const newState = Object.assign({}, state, { items: action.state.items });
    return newState;
  },

  [types.VEHICLES_SET_PRODUCT](state, action) {
    const newState = Object.assign({}, state, { item: action.state.item });

    return newState;
  },

  [types.VEHICLES_SET_PRODUCTS_COUNT](state, action) {
    const newState = Object.assign({}, state, { count: action.state.count });
    return newState;
  },

  [types.VEHICLES_SET_PRODUCTS_TOTAL_COUNT](state, action) {
    const newState = Object.assign({}, state, {
      totalCount: action.state.totalCount,
    });
    return newState;
  },

  [types.VEHICLES_SET_CREATED_PRODUCT](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    newState.createdItem = action.state.item;
    newState.items.unshift(action.state.item);

    return newState;
  },

  [types.VEHICLES_SET_UPDATED_PRODUCT](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    newState.updatedItem = action.state.item;

    if (newState.item) {
      newState.item = action.state.item;
    }

    newState.items = newState.items.map((itm) => {
      if (itm.id === action.state.item.id) {
        Object.assign(itm, action.state.item);
      }

      return itm;
    });

    return newState;
  },

  [types.VEHICLES_SET_DELETED_PRODUCT](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    newState.items = newState.items.filter((i) => i.id !== action.state.itemId);
    return newState;
  },

  [types.VEHICLES_SET_NEW_PRODUCT_DEFAULT_TASK](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    if (!newState.item.taskDefinitions) {
      newState.item.taskDefinitions = [];
    }
    newState.item.taskDefinitions.unshift(action.state.defaultTask);

    return newState;
  },

  [types.VEHICLES_SET_DELETED_PRODUCT_DEFAULT_TASK](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    newState.item.taskDefinitions = newState.item.taskDefinitions.filter(
      (i) => i.id !== action.state.defaultTaskId
    );
    return newState;
  },

  [types.VEHICLES_SET_PRODUCT_COMPLETION_TIMES](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    newState.completionTimes = action.state.item;

    return newState;
  },
});
