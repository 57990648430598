import items from "./items";
import events from "./events";
import users from "./users";
import contacts from "./contacts";
import contactsGroups from "./contactsGroups";
import userWorks from "./userWorks";
import orders from "./orders";
import orderCategories from "./orderCategories";
import orderGroups from "./orderGroups";
import workTimes from "./workTimes";
import products from "./products";
import itemsSharings from "./itemsSharings";
import kboxs from "./kboxs";
import itemsTransfers from "./itemsTransfers";
import quotes from "./quotes";
import pipelineTemplates from "./pipelineTemplates";
import pipelinesSharings from "./pipelinesSharings";
import pipelines from "./pipelines";
import taskDefinitions from "./taskDefinitions";
import feedbackAlerts from "./feedbackAlerts";
import ordersTasks from "./ordersTasks";
import damageRequests from "./damageRequests";
import leaves from "./leaves";
import lates from "./lates";
import multiDashGroups from "./multiDashGroups";
import projectedCosts from "./projectedCosts";

export default {
  items,
  events,
  users,
  contacts,
  contactsGroups,
  userWorks,
  orders,
  orderCategories,
  orderGroups,
  workTimes,
  products,
  itemsSharings,
  kboxs,
  itemsTransfers,
  quotes,
  pipelineTemplates,
  pipelines,
  taskDefinitions,
  feedbackAlerts,
  ordersTasks,
  pipelinesSharings,
  damageRequests,
  leaves,
  lates,
  multiDashGroups,
  projectedCosts,
};
