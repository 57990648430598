import createReducer from "../../lib/createReducer";
import * as types from "../../actions/types";

const initialState = {
  items: [],
  item: null,
  selfItem: null,
  count: 0,
  totalCount: 0,
  nextToken: null,
  productCustomPrices: {},
  productCustomPricesForMe: {},
};

export default createReducer(initialState, {
  /**
   * CONTACTS ======================================================================================
   */

  [types.VEHICLES_SET_CONTACTS](state, action) {
    const newState = Object.assign({}, state, { items: action.state.items });
    return newState;
  },

  [types.VEHICLES_SET_CONTACTS_NEXT_TOKEN](state, action) {
    const newState = Object.assign({}, state, {
      nextToken: action.state.nextToken,
    });
    return newState;
  },

  [types.VEHICLES_SET_CONTACT](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    newState.item = action.state.item;
    return newState;
  },

  [types.VEHICLES_SET_DELETED_CONTACT](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { contactId } = action.state;

    // Delete contact from contacts list
    newState.items = newState.items.filter((u) => u.id !== contactId);

    return newState;
  },

  [types.VEHICLES_SET_CREATED_CONTACT](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const contact = action.state.item;

    newState.items.unshift(contact);
    return newState;
  },

  [types.VEHICLES_SET_CONTACT_ARCHIVED](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { contactId } = action.state;

    newState.items.forEach((i) => {
      if (i.id === contactId) {
        i.archived = true;
      }
    });

    if (newState.item && newState.item.id === contactId) {
      newState.item.archived = true;
    }

    if (newState.selfItem && newState.selfItem.id === contactId) {
      newState.selfItem.archived = true;
    }

    return newState;
  },

  [types.VEHICLES_SET_CONTACT_UNARCHIVED](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { contactId } = action.state;

    newState.items.forEach((i) => {
      if (i.id === contactId) {
        i.archived = false;
      }
    });

    if (newState.item && newState.item.id === contactId) {
      newState.item.archived = false;
    }

    if (newState.selfItem && newState.selfItem.id === contactId) {
      newState.selfItem.archived = false;
    }

    return newState;
  },

  /**
   * CONTACTS COUNT ================================================================================
   */

  [types.VEHICLES_SET_CONTACTS_COUNT](state, action) {
    const newState = Object.assign({}, state, { count: action.state.count });
    return newState;
  },

  [types.VEHICLES_SET_CONTACTS_TOTAL_COUNT](state, action) {
    const newState = Object.assign({}, state, {
      totalCount: action.state.totalCount,
    });
    return newState;
  },

  /**
   * PRODUCT CUSTOM PRICES =========================================================================
   */

  [types.VEHICLES_SET_CONTACT_PRODUCT_CUSTOM_PRICES](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { items, contactId } = action.state;

    newState.productCustomPrices[contactId] =
      newState.productCustomPrices[contactId] || {};
    newState.productCustomPrices[contactId].items = items;

    return newState;
  },

  [types.VEHICLES_SET_CONTACT_IMPORTED_PRODUCT_CUSTOM_PRICES](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { items, contactId } = action.state;

    let data = newState.productCustomPrices[contactId];

    data = data || {};
    data.items = data.items || [];
    if (items.length > 0) {
      items.forEach((item) => {
        data.items.unshift(item);
      });
      data.totalCount = data.totalCount || 0;
      data.totalCount += items.length;
    }

    newState.productCustomPrices[contactId] = data;
    return newState;
  },

  [types.VEHICLES_SET_CONTACT_PRODUCT_CUSTOM_PRICES_TOTAL_COUNT](
    state,
    action
  ) {
    const newState = JSON.parse(JSON.stringify(state));
    const { totalCount, contactId } = action.state;
    newState.productCustomPrices[contactId] =
      newState.productCustomPrices[contactId] || {};
    newState.productCustomPrices[contactId].totalCount = totalCount;
    return newState;
  },

  [types.VEHICLES_SET_CONTACT_PRODUCT_CUSTOM_PRICES_COUNT](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { count, contactId } = action.state;
    newState.productCustomPrices[contactId] =
      newState.productCustomPrices[contactId] || {};
    newState.productCustomPrices[contactId].count = count;
    return newState;
  },

  [types.VEHICLES_SET_CONTACT_PRODUCT_CUSTOM_PRICES_NEXT_TOKEN](state, action) {
    const newState = Object.assign({}, state, {
      nextToken: action.state.nextToken,
    });
    return newState;
  },

  [types.VEHICLES_SET_CONTACT_CREATED_PRODUCT_CUSTOM_PRICE](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { item, contactId } = action.state;

    let data = newState.productCustomPrices[contactId];

    data = data || {};
    data.items = data.items || [];
    data.items.unshift(item);
    data.totalCount = data.totalCount || 0;
    data.totalCount += 1;
    newState.productCustomPrices[contactId] = data;

    return newState;
  },

  [types.VEHICLES_SET_CONTACT_DELETED_PRODUCT_CUSTOM_PRICE](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { productId, contactId } = action.state;

    let data = newState.productCustomPrices[contactId];

    data = data || {};
    data.items = data.items || [];
    data.items = newState.productCustomPrices[contactId].items.filter(
      (u) => u.id !== productId
    );
    data.totalCount = data.totalCount || 0;
    data.totalCount -= 1;
    newState.productCustomPrices[contactId] = data;

    return newState;
  },

  [types.VEHICLES_UPDATED_CONTACT_PRODUCT_CUSTOM_PRICE](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { contactId, productId, payload } = action.state;

    const data = newState.productCustomPrices[contactId];

    if (data) {
      data.items.forEach((item, index) => {
        if (item.id === productId) {
          data.items[index].price = payload.price;
          data.items[index].secondSeries = payload.secondSeries;
        }
      });

      newState.productCustomPrices[contactId] = data;
    }

    return newState;
  },

  // FOR ME

  [types.VEHICLES_SET_CONTACT_PRODUCT_CUSTOM_PRICES_TOTAL_COUNT_FOR_ME](
    state,
    action
  ) {
    const newState = JSON.parse(JSON.stringify(state));
    const { totalCount, contactId } = action.state;

    newState.productCustomPricesForMe[contactId] =
      newState.productCustomPricesForMe[contactId] || {};

    newState.productCustomPricesForMe[contactId].totalCount = totalCount;
    return newState;
  },

  [types.VEHICLES_SET_CONTACT_PRODUCT_CUSTOM_PRICES_COUNT_FOR_ME](
    state,
    action
  ) {
    const newState = JSON.parse(JSON.stringify(state));
    const { count, contactId } = action.state;

    newState.productCustomPricesForMe[contactId] =
      newState.productCustomPricesForMe[contactId] || {};

    newState.productCustomPricesForMe[contactId].count = count;
    return newState;
  },

  [types.VEHICLES_SET_CONTACT_PRODUCT_CUSTOM_PRICES_FOR_ME](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { items, contactId } = action.state;

    newState.productCustomPricesForMe[contactId] =
      newState.productCustomPricesForMe[contactId] || {};

    newState.productCustomPricesForMe[contactId].items = items;

    return newState;
  },
});
