import createReducer from "../../lib/createReducer";
import * as types from "../../actions/types";

const initialState = {
  items: [],
  createdItem: null,
};

export default createReducer(initialState, {
  [types.VEHICLES_SET_USER_WORKS](state, action) {
    const newState = Object.assign({}, state, { items: action.state.items });
    return newState;
  },

  [types.VEHICLES_SET_CREATED_USER_WORK](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    newState.createdItem = action.state.item;
    newState.items.unshift(newState.createdItem);

    return newState;
  },

  [types.VEHICLES_SET_DELETED_USER_WORK](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { item } = action.state;

    newState.items = newState.items.filter((i) => i.name !== item.name);

    return newState;
  },

  [types.VEHICLES_SET_UPDATED_USER_WORK](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { item } = action.state;

    newState.items = newState.items.map((i) => {
      if (i.name === item.name) {
        return item;
      }
      return i;
    });

    return newState;
  },
});
