import createReducer from "../../lib/createReducer";
import * as types from "../../actions/types";

const initialState = {
  items: [],
  item: null,
};

export default createReducer(initialState, {
  [types.VEHICLES_SET_KBOXS](state, action) {
    const newState = Object.assign({}, state, { items: action.state.items });

    return newState;
  },

  [types.VEHICLES_SET_KBOX](state, action) {
    const newState = Object.assign({}, state, { item: action.state.item });

    return newState;
  },

  [types.VEHICLES_SET_DELETED_KBOX](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    newState.items = newState.items.filter((b) => b.id !== action.state.kboxId);

    return newState;
  },
});
