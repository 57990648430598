import React, { Component } from "react";
import PropTypes from "prop-types";

import { Typography } from "antd";
import { BugOutlined } from "@ant-design/icons";

const { Title, Paragraph } = Typography;

export default class ErrorBoundary extends Component {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  // eslint-disable-next-line
  componentDidCatch() {
    // @TODO: Capture with sentry
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="fh ce">
          <div style={{ textAlign: "center" }}>
            <BugOutlined style={{ fontSize: "100px" }} />

            <Title>Une erreur a empêchée la page de s&#39;afficher.</Title>

            <Paragraph>
              Si le problème persiste, veuillez &nbsp;
              <a
                href="http://www.keematic.com/contact"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                nous contacter{" "}
              </a>
              &nbsp;en nous décrivant le problème.
            </Paragraph>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any,
};
