import createReducer from "../../lib/createReducer";
import * as types from "../../actions/types";

const initialState = {
  items: [],
  createdItem: null,
  deletedItemId: null,
};

export default createReducer(initialState, {
  [types.VEHICLES_SET_FEEDBACK_ALERTS](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    newState.items = action.state.items;

    return newState;
  },

  [types.VEHICLES_SET_CREATED_FEEDBACK_ALERT](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    newState.createdItem = action.state.item;
    newState.items.unshift(newState.createdItem);

    return newState;
  },

  [types.VEHICLES_SET_DELETED_FEEDBACK_ALERT](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    const { itemId } = action.state;
    newState.deletedItemId = itemId;
    newState.items = newState.items.filter((i) => i.alert.id !== itemId);

    return newState;
  },

  [types.VEHICLES_SET_UPDATED_FEEDBACK_ALERT](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    newState.items = newState.items.map((itm) => {
      if (itm.alert.id === action.state.itemId) {
        itm = action.state.item;
      }

      return itm;
    });

    return newState;
  },
});
