import createReducer from "../../lib/createReducer";
import * as types from "../../actions/types";

const initialState = {
  item: null,
  items: [],

  totalCount: 0,
};

export default createReducer(initialState, {
  [types.VEHICLES_SET_PIPELINES](state, action) {
    const newState = Object.assign({}, state, { items: action.state.items });

    return newState;
  },

  [types.VEHICLES_SET_PIPELINES_TOTALCOUNT](state, action) {
    const newState = Object.assign({}, state, {
      totalCount: action.state.totalCount,
    });

    return newState;
  },

  [types.VEHICLES_SET_PIPELINE](state, action) {
    const newState = Object.assign({}, state, { item: action.state.item });

    return newState;
  },
});
