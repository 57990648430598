import createReducer from "../../lib/createReducer";
import * as types from "../../actions/types";

const initialState = {
  items: [],
};

export default createReducer(initialState, {
  [types.VEHICLES_SET_ITEM_EVENTS](state, action) {
    const newState = Object.assign({}, state, { items: action.state.items });
    return newState;
  },
});
