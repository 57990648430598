require("../css/app.less");
require("../css/app.scss");

// Get config (APP_CONFIG is auto replaced by webpack)
window.appConfig = APP_CONFIG; // eslint-disable-line

// Get version (APP_CONFIG is auto replaced by webpack)
window.appVersion = APP_VERSION; // eslint-disable-line

// Load & init sentry
// const Sentry = require('@sentry/browser');

// Sentry.init({dsn: window.appConfig.sentryDsn});

window.addEventListener("load", () => {
  if (navigator.serviceWorker && window.appConfig.env !== "dev") {
    navigator.serviceWorker.register("/service-worker.js");
  }
});

// Run React application
require("./app");
