import createReducer from "../../lib/createReducer";
import * as types from "../../actions/types";

const initialState = {
  items: [],
  totalCount: 0,
};

export default createReducer(initialState, {
  [types.VEHICLES_SET_ORDERS_TASKS](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    newState.items = action.state.items;
    return newState;
  },

  [types.VEHICLES_SET_ORDERS_TASKS_TOTAL_COUNT](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    newState.totalCount = action.state.totalCount;
    return newState;
  },
});
