import createReducer from "../../lib/createReducer";
import * as types from "../../actions/types";

const initialState = {
  items: [],
  createdItem: null,
  deletedItemId: null,
};

export default createReducer(initialState, {
  [types.VEHICLES_SET_ORDER_GROUPS](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    newState.items = action.state.items;

    return newState;
  },

  [types.VEHICLES_SET_CREATED_ORDER_GROUP](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    newState.createdItem = action.state.item;
    newState.items.unshift(newState.createdItem);

    return newState;
  },

  [types.VEHICLES_SET_DELETED_ORDER_GROUP_ID](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    const { itemId } = action.state;
    newState.deletedItemId = itemId;
    newState.items = newState.items.filter((i) => i.id !== itemId);

    return newState;
  },
});
