import createReducer from "../../lib/createReducer";
import * as types from "../../actions/types";

const initialState = {
  username: null,
  attributes: null,
};

export default createReducer(initialState, {
  [types.ACCOUNT_SET_CURRENT_USER](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    newState.username = action.state.currentUser.username;
    newState.attributes = action.state.currentUser.attributes;

    return newState;
  },
});
