import axios from "axios";
import { Auth } from "aws-amplify";
import { store } from "../store";

const apisUrls = {
  account: window.appConfig.accountApiBaseUrl,
  vehicles: window.appConfig.vehiclesApiBaseUrl,
};

const apis = {};

/**
 * Set auth tokens before each requests
 *
 * @param {Object} config
 *
 * @return {Promise}
 */
async function authRequestInterceptor(config) {
  // Get current selected entity id
  const state = store.getState();
  let selectedEntityId = null;

  if (
    state.account &&
    state.account.roles &&
    state.account.roles.selectedRole
  ) {
    selectedEntityId = state.account.roles.selectedRole.entity.id;
  }

  return Auth.userSession(Auth.user)
    .then((session) => {
      Object.assign(config.headers, {
        "Content-Type": "application/json",
        Authorization: session.idToken.jwtToken,
      });

      if (selectedEntityId !== null) {
        const existingHeaders = Object.keys(config.headers).map((k) =>
          k.toLowerCase()
        );

        if (existingHeaders.indexOf("x-selected-entity") === -1) {
          Object.assign(config.headers, {
            "X-Selected-Entity": selectedEntityId,
          });
        }
      }

      return config;
    })
    .catch(() => config);
}

/**
 * Check server response & replay request with new accessToken if status === 401
 *
 * @param {Object} The axios instance
 *
 * @return {Function}
 */
function authResponseInterceptor(instance) {
  return async (response, err) => {
    if (!err) {
      return response;
    }

    const error = err.response;

    if (error.status === 401 && error.config && !error.config.isRetry) {
      error.config.isRetry = true;

      // Get new tokens && replay request
      return Auth.currentSession().then(() => instance(error.config));
    }
    return response;
  };
}

// Create axios instances
Object.keys(apisUrls).forEach((apiName) => {
  const baseURL = apisUrls[apiName];
  const instance = axios.create({ baseURL });

  instance.interceptors.request.use(authRequestInterceptor);
  instance.interceptors.response.use(authResponseInterceptor(instance));

  apis[apiName] = instance;
});

export default apis;
