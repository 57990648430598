import React, { lazy } from "react";
import { Switch, Route } from "react-router-dom";
import suspensify from "../boot/suspensify";

const CompanyLayout = lazy(() =>
  import(
    /* webpackChunkName: "EntityLayoutComponent" */
    "../components/layouts/CompanyLayout"
  )
);

const EntityInformations = lazy(() =>
  import(
    /* webpackChunkName: "CompanyInformationsComponent" */
    "../containers/company/Informations"
  )
);

export default function Company() {
  return (
    <CompanyLayout>
      <Switch>
        {/* Informations */}

        <Route
          exact
          path="/company/informations"
          component={suspensify(EntityInformations, "pageLoading")}
        />
      </Switch>
    </CompanyLayout>
  );
}
