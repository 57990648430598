import axios from "axios";

/**
 * Get current version from window.appConfig
 *
 * @return {Object}
 */
export function getCurrentVersion() {
  return window.appVersion;
}

/**
 * Fetch new version from '/version.json'
 *
 * @return {Object}
 */
export async function fetchNewVersion() {
  const timeTag = +new Date().getTime();
  const response = await axios.get(`/version.json?_=${timeTag}`);
  return response.data;
}
