import * as Sentry from "@sentry/browser";
import * as types from "../types";
import storage from "../../boot/storage";
import api from "../../boot/api";

export function setCurrentUserRoles(currentUserRoles) {
  return {
    type: types.ACCOUNT_SET_CURRENT_USER_ROLES,
    state: { currentUserRoles },
  };
}

export function setSelectedRole(selectedRole) {
  // Set selected role in sentry
  Sentry.configureScope((scope) => {
    scope.setExtra("selectedRole", selectedRole);
  });

  return {
    type: types.ACCOUNT_SET_SELECTED_ROLE,
    state: { selectedRole },
  };
}

export function getCurrentUserRoles() {
  return (dispatch) =>
    api.account.get("/roles", {}).then((response) => {
      dispatch(setCurrentUserRoles(response.data));
      return response.data;
    });
}

export function saveAndSetSelectedRole(role) {
  return (dispatch) =>
    storage
      .setItem("account/selectedRole", JSON.stringify(role))
      .then(() => dispatch(setSelectedRole(role)));
}

export function deleteSelectedRole() {
  return () => storage.removeItem("account/selectedRole");
}

export function getSelectedRole() {
  return () =>
    storage.getItem("account/selectedRole").then((value) => {
      try {
        return JSON.parse(value);
      } catch (e) {
        return null;
      }
    });
}
