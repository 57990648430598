import createReducer from "../../lib/createReducer";
import * as types from "../../actions/types";

const initialState = {
  items: [],
  count: 0,
  totalCount: 0,

  item: null,
  averageFeedback: {},
  absenteeism: {},
  lates: {},

  nextToken: null,
};

export default createReducer(initialState, {
  [types.VEHICLES_SET_USERS](state, action) {
    const newState = Object.assign({}, state, { items: action.state.items });
    return newState;
  },

  [types.VEHICLES_SET_USERS_COUNT](state, action) {
    const newState = Object.assign({}, state, { count: action.state.count });
    return newState;
  },

  [types.VEHICLES_SET_USERS_TOTAL_COUNT](state, action) {
    const newState = Object.assign({}, state, {
      totalCount: action.state.totalCount,
    });
    return newState;
  },

  [types.VEHICLES_SET_USERS_NEXT_TOKEN](state, action) {
    const newState = Object.assign({}, state, {
      nextToken: action.state.nextToken,
    });
    return newState;
  },

  [types.VEHICLES_SET_USER](state, action) {
    const newState = Object.assign({}, state, { item: action.state.item });
    return newState;
  },

  [types.VEHICLES_SET_USER_AVERAGE_FEEDBACK](state, action) {
    const newState = Object.assign({}, state, {
      averageFeedback: action.state.item,
    });
    return newState;
  },

  [types.VEHICLES_SET_USER_ABSENTEEISM](state, action) {
    const newState = Object.assign({}, state, {
      absenteeism: action.state.item,
    });
    return newState;
  },

  [types.VEHICLES_SET_USER_LATES](state, action) {
    const newState = Object.assign({}, state, {
      lates: action.state.item,
    });
    return newState;
  },

  [types.VEHICLES_SET_DELETED_USER](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { userName } = action.state;

    // Delete user from users list
    newState.items = newState.items.filter((u) => u.userName !== userName);

    return newState;
  },

  [types.VEHICLES_SET_USERS_DISABLED](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { userName } = action.state;

    if (newState.item && newState.item.userName === userName) {
      newState.item.enabled = false;
    }

    newState.items.map((i) => {
      if (i.userName === userName) {
        i.user.enabled = false;
      }

      return i;
    });

    return newState;
  },

  [types.VEHICLES_SET_USERS_ENABLED](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { userName } = action.state;

    if (newState.item && newState.item.userName === userName) {
      newState.item.enabled = true;
    }

    newState.items.map((i) => {
      if (i.userName === userName) {
        i.user.enabled = true;
      }

      return i;
    });

    return newState;
  },

  [types.VEHICLES_SET_USER_ROLE](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { userName, roleLevel } = action.state;

    newState.items.map((i) => {
      if (i.user.userName === userName) {
        i.roleLevel = roleLevel;
      }

      return i;
    });

    return newState;
  },

  [types.VEHICLES_SET_WORK_HOURS](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { userName, data } = action.state;

    if (newState.item && newState.item.userName === userName) {
      newState.item.workHours = data.workHours;
      newState.item.userWorkName = data.userWorkName;
    }

    newState.items.map((i) => {
      if (i.user.userName === userName) {
        i.workHours = data.workHours;
        i.userWorkName = data.userWorkName;
      }

      return i;
    });

    return newState;
  },
});
