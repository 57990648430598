import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useLocation, useHistory } from "react-router-dom";

import { notification, Spin, Button } from "antd";

import * as version from "../boot/version";

import {
  getSelectedRole,
  setSelectedRole,
  getCurrentUserRoles,
  deleteSelectedRole,
} from "../actions/account/roles";

import { getCurrentUser } from "../actions/account/currentUser";
import BrowserNotSupported from "./BrowserNotSupported";

function App(props) {
  const [ready, setReady] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const isIe = require("@braintree/browser-detection/is-ie");

  const watchForNewVersion = () => {
    const currentVersion = version.getCurrentVersion();

    // eslint-disable-next-line
    let intervalId;

    const watch = async () => {
      try {
        const newVersion = await version.fetchNewVersion();

        if (newVersion.hash !== currentVersion.hash) {
          clearInterval(intervalId);

          notification.warn({
            duration: 0,
            message: "Nouvelle version",
            description: `
              Rechargez la page le plus rapidement possible en cliquant sur cette boîte
              de dialogue ou en utilisant la fonction "recharger" de votre navigateur.
            `.trim(),
            btn: (
              <Button
                shape="round"
                type="primary"
                disabled={location.pathname.split("/")[1] === "auth"}
                onClick={() => history.push("/vehicles/versions")}
              >
                Voir les notes de mise à jour
              </Button>
            ),
            onClick: () => {
              document.location.reload(true);
            },
            onClose: () => {
              document.location.reload(true);
            },
          });
        }
      } catch (err) {}
    };

    intervalId = setInterval(watch, 60000);
    watch();
  };

  useEffect(() => {
    (async () => {
      // Watch for new version (ask user to reload page)
      watchForNewVersion();

      const noRedirPaths = [
        /\/auth\/login/,
        /\/auth\/confirm-account/,
        /\/auth\/forgot-password/,
        /\/auth\/forgot-password-confirm/,
        /\/vehicles\/invitations\/.*/,
        /\/old-domain-redirect.*/,
      ];

      try {
        await props.getCurrentUser();
        const roles = await props.getCurrentUserRoles();
        const role = await props.getSelectedRole();

        if (!role) {
          history.push("/auth/select-entity");
          setReady(true);
          return;
        }

        // Check role still exist
        let roleExist = false;

        roles.forEach((rr) => {
          if (rr.role.id === role.role.id) {
            roleExist = true;
          }
        });

        if (!roleExist) {
          props.deleteSelectedRole();
          history.push("/auth/select-entity");
          setReady(true);
          return;
        }

        props.setSelectedRole(role);

        setReady(true);
      } catch (err) {
        let ready = false;

        noRedirPaths.forEach((r) => {
          if (r.test(location.pathname)) {
            ready = true;
          }
        });

        if (ready) {
          setReady(true);
          return;
        }

        history.push("/auth/login");
        setReady(true);
      }
    })();
  }, []);

  if (isIe()) {
    return <BrowserNotSupported />;
  }

  if (!ready) {
    return (
      <div className="fh ce">
        <Spin />
      </div>
    );
  }

  return props.children;
}

// @propTypes
App.propTypes = {
  location: PropTypes.object,
  roles: PropTypes.array,
  getCurrentUserRoles: PropTypes.func.isRequired,
  getCurrentUser: PropTypes.func.isRequired,
  getSelectedRole: PropTypes.func.isRequired,
  setSelectedRole: PropTypes.func.isRequired,
  deleteSelectedRole: PropTypes.func.isRequired,
  children: PropTypes.any,
};

// @redux mapDispatchToProps
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCurrentUserRoles,
      getCurrentUser,
      setSelectedRole,
      getSelectedRole,
      deleteSelectedRole,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(App);
