import createReducer from "../../lib/createReducer";
import * as types from "../../actions/types";

const initialState = {
  items: [],

  selectedItem: null,
};

export default createReducer(initialState, {
  [types.VEHICLES_SET_PIPELINE_TEMPLATES](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    newState.items = action.state.items;

    return newState;
  },

  [types.VEHICLES_SET_DELETED_PIPELINE_TEMPLATE](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    const { itemId } = action.state;
    newState.items = newState.items.filter((i) => i.id !== itemId);

    return newState;
  },

  [types.VEHICLES_SET_SELECTED_PIPELINE_TEMPLATES](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    newState.selectedItem = action.state.item;

    return newState;
  },
});
