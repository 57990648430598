/**
 * =================================================================================================
 * APP =============================================================================================
 * =================================================================================================
 */

export const APP_SET_ONLINE_STATE = "APP_SET_ONLINE_STATE";
export const APP_SET_APP_NAME = "APP_SET_APP_NAME";
export const APP_SET_MENU_COLLAPSED = "APP_SET_MENU_COLLAPSED";

/**
 * =================================================================================================
 * UTILS ===========================================================================================
 * =================================================================================================
 */

/**
 * Breadcrump
 */

export const BREADCRUMP_SET_ITEMS = "BREADCRUMP_SET_ITEMS";

/**
 * Messages
 */

export const MESSAGES_ADD_MESSAGE = "MESSAGES_ADD_MESSAGE";

/**
 * =================================================================================================
 * ACCOUNT =========================================================================================
 * =================================================================================================
 */

export const ACCOUNT_SET_CURRENT_USER = "ACCOUNT_SET_CURRENT_USER";
export const ACCOUNT_SET_CURRENT_USER_ROLES = "ACCOUNT_SET_CURRENT_USER_ROLES";
export const ACCOUNT_SET_SELECTED_ROLE = "ACCOUNT_SET_SELECTED_ROLE";
export const ACCOUNT_SET_APP_SETTINGS = "ACCOUNT_SET_APP_SETTINGS";
export const ACCOUNT_SET_INVITATION = "ACCOUNT_SET_INVITATION";

/**
 * =================================================================================================
 * VEHICLES ========================================================================================
 * =================================================================================================
 */

/**
 * Items
 */
export const VEHICLES_SET_ITEMS = "VEHICLES_SET_ITEMS";
export const VEHICLES_SET_ITEMS_COUNT = "VEHICLES_SET_ITEMS_COUNT";
export const VEHICLES_SET_ITEMS_TOTAL_COUNT = "VEHICLES_SET_ITEMS_TOTAL_COUNT";
export const VEHICLES_SET_ITEMS_NEXT_TOKEN = "VEHICLES_SET_ITEMS_NEXT_TOKEN";
export const VEHICLES_SET_ITEM = "VEHICLES_SET_ITEM";
export const VEHICLES_SET_ITEM_ARCHIVED = "VEHICLES_SET_ITEM_ARCHIVED";
export const VEHICLES_SET_ITEM_UNARCHIVED = "VEHICLES_SET_ITEM_UNARCHIVED";
export const VEHICLES_ADD_ITEM_FOR_UNLOCK = "VEHICLES_ADD_ITEM_FOR_UNLOCK";
export const VEHICLES_REMOVE_ITEM_FOR_UNLOCK =
  "VEHICLES_REMOVE_ITEM_FOR_UNLOCK";
export const VEHICLES_TOGGLE_UNLOCK_DRAWER = "VEHICLES_TOGGLE_UNLOCK_DRAWER";
export const VEHICLES_CLEAR_ITEMS_FOR_UNLOCK =
  "VEHICLES_CLEAR_ITEMS_FOR_UNLOCK";
export const VEHICLES_SET_DELETED_ITEM = "VEHICLES_SET_DELETED_ITEM";
export const VEHICLES_SET_CORE_STATE_ITEM = "VEHICLES_SET_CORE_STATE_ITEM";

/**
 * Events
 */

export const VEHICLES_SET_ITEM_EVENTS = "VEHICLES_SET_ITEM_EVENTS";

/**
 * Users
 */
export const VEHICLES_SET_USERS = "VEHICLES_SET_USERS";
export const VEHICLES_SET_CREATED_USER = "VEHICLES_SET_CREATED_USER";
export const VEHICLES_SET_DELETED_USER = "VEHICLES_SET_DELETED_USER";
export const VEHICLES_SET_USERS_COUNT = "VEHICLES_SET_USERS_COUNT";
export const VEHICLES_SET_USERS_TOTAL_COUNT = "VEHICLES_SET_USERS_TOTAL_COUNT";
export const VEHICLES_SET_USERS_NEXT_TOKEN = "VEHICLES_SET_USERS_NEXT_TOKEN";
export const VEHICLES_SET_USER = "VEHICLES_SET_USER";
export const VEHICLES_SET_USERS_DISABLED = "VEHICLES_SET_USERS_DISABLED";
export const VEHICLES_SET_USERS_ENABLED = "VEHICLES_SET_USERS_ENABLED";
export const VEHICLES_SET_USER_ROLE = "VEHICLES_SET_USER_ROLE";
export const VEHICLES_SET_USER_AVERAGE_FEEDBACK =
  "VEHICLES_SET_USER_AVERAGE_FEEDBACK";
export const VEHICLES_SET_USER_ABSENTEEISM = "VEHICLES_SET_USER_ABSENTEEISM";
export const VEHICLES_SET_USER_LATES = "VEHICLES_SET_USER_LATES";
export const VEHICLES_SET_WORK_HOURS = "VEHICLES_SET_WORK_HOURS";

/**
 * Contacts
 */
export const VEHICLES_SET_CONTACTS = "VEHICLES_SET_CONTACTS";
export const VEHICLES_SET_CREATED_CONTACT = "VEHICLES_SET_CREATED_CONTACT";
export const VEHICLES_SET_DELETED_CONTACT = "VEHICLES_SET_DELETED_CONTACT";
export const VEHICLES_SET_CONTACTS_COUNT = "VEHICLES_SET_CONTACTS_COUNT";
export const VEHICLES_SET_CONTACTS_TOTAL_COUNT =
  "VEHICLES_SET_CONTACTS_TOTAL_COUNT";
export const VEHICLES_SET_CONTACTS_NEXT_TOKEN =
  "VEHICLES_SET_CONTACTS_NEXT_TOKEN";
export const VEHICLES_SET_CONTACT = "VEHICLES_SET_CONTACT";

export const VEHICLES_SET_CONTACT_ARCHIVED = "VEHICLES_SET_CONTACT_ARCHIVED";
export const VEHICLES_SET_CONTACT_UNARCHIVED =
  "VEHICLES_SET_CONTACT_UNARCHIVED";

export const VEHICLES_SET_CONTACT_CREATED_PRODUCT_CUSTOM_PRICE =
  "VEHICLES_SET_CONTACT_CREATED_PRODUCT_CUSTOM_PRICE";
export const VEHICLES_SET_CONTACT_PRODUCT_CUSTOM_PRICES =
  "VEHICLES_SET_CONTACT_PRODUCT_CUSTOM_PRICES";
export const VEHICLES_SET_CONTACT_DELETED_PRODUCT_CUSTOM_PRICE =
  "VEHICLES_SET_CONTACT_DELETED_PRODUCT_CUSTOM_PRICE";
export const VEHICLES_SET_CONTACT_PRODUCT_CUSTOM_PRICES_TOTAL_COUNT =
  "VEHICLES_SET_CONTACT_PRODUCT_CUSTOM_PRICES_TOTAL_COUNT";
export const VEHICLES_SET_CONTACT_PRODUCT_CUSTOM_PRICES_COUNT =
  "VEHICLES_SET_CONTACT_PRODUCT_CUSTOM_PRICES_COUNT";
export const VEHICLES_SET_CONTACT_PRODUCT_CUSTOM_PRICES_NEXT_TOKEN =
  "VEHICLES_SET_CONTACT_PRODUCT_CUSTOM_PRICES_NEXT_TOKEN";
export const VEHICLES_UPDATED_CONTACT_PRODUCT_CUSTOM_PRICE =
  "VEHICLES_UPDATED_CONTACT_PRODUCT_CUSTOM_PRICE";
export const VEHICLES_SET_CONTACT_IMPORTED_PRODUCT_CUSTOM_PRICES =
  "VEHICLES_SET_CONTACT_IMPORTED_PRODUCT_CUSTOM_PRICES";

export const VEHICLES_SET_CONTACT_PRODUCT_CUSTOM_PRICES_FOR_ME =
  "VEHICLES_SET_CONTACT_PRODUCT_CUSTOM_PRICES_FOR_ME";
export const VEHICLES_SET_CONTACT_PRODUCT_CUSTOM_PRICES_TOTAL_COUNT_FOR_ME =
  "VEHICLES_SET_CONTACT_PRODUCT_CUSTOM_PRICES_TOTAL_COUNT_FOR_ME";
export const VEHICLES_SET_CONTACT_PRODUCT_CUSTOM_PRICES_COUNT_FOR_ME =
  "VEHICLES_SET_CONTACT_PRODUCT_CUSTOM_PRICES_COUNT_FOR_ME";

/**
 * Contacts Groups
 */

export const VEHICLES_SET_CONTACTS_GROUPS_SET_GROUPS =
  "VEHICLES_SET_CONTACTS_GROUPS_SET_GROUPS";
export const VEHICLES_SET_CONTACTS_SET_GROUP =
  "VEHICLES_SET_CONTACTS_SET_GROUP";
export const VEHICLES_CONTACTS_GROUPS_SET_DELETED_GROUP =
  "VEHICLES_CONTACTS_GROUPS_SET_DELETED_GROUP";
export const VEHICLES_CONTACTS_GROUPS_CREATED_GROUP =
  "VEHICLES_CONTACTS_GROUPS_CREATED_GROUP";
export const VEHICLES_CONTACTS_GROUPS_UPDATED_GROUP =
  "VEHICLES_CONTACTS_GROUPS_UPDATED_GROUPs";
export const VEHICLES_CONTACTS_GROUPS_SET_GROUPS_COUNT =
  "VEHICLES_CONTACTS_GROUPS_SET_GROUPS_COUNT";
export const VEHICLES_CONTACTS_GROUPS_SET_GROUPS_TOTAL_COUNT =
  "VEHICLES_CONTACTS_GROUPS_SET_GROUPS_TOTAL_COUNT";
export const VEHICLES_SET_CONTACTS_GROUPS_CREATED_CUSTOM_PRICE =
  "VEHICLES_SET_CONTACTS_GROUPS_CREATED_CUSTOM_PRICE";
export const VEHICLES_SET_CONTACTS_GROUPS_CUSTOM_PRICES =
  "VEHICLES_SET_CONTACTS_GROUPS_CUSTOM_PRICES";
export const VEHICLES_CONTACTS_GROUPS_SET_IMPORTED_CUSTOM_PRICES =
  "VEHICLES_CONTACTS_GROUPS_SET_IMPORTED_CUSTOM_PRICES";
export const VEHICLES_CONTACTS_GROUPS_SET_DELETED_CUSTOM_PRICE =
  "VEHICLES_CONTACTS_GROUPS_SET_DELETED_CUSTOM_PRICE";
export const VEHICLES_CONTACTS_GROUPS_SET_CUSTOM_PRICES_COUNT =
  "VEHICLES_CONTACTS_GROUPS_SET_CUSTOM_PRICES_COUNT";
export const VEHICLES_CONTACTS_GROUPS_SET_CUSTOM_PRICES_TOTAL_COUNT =
  "VEHICLES_CONTACTS_GROUPS_SET_CUSTOM_PRICES_TOTAL_COUNT";
export const VEHICLES_CONTACTS_GROUPS_UPDATED_CUSTOM_PRICE =
  "VEHICLES_CONTACTS_GROUPS_UPDATED_CUSTOM_PRICE";

/**
 * User works
 */
export const VEHICLES_SET_USER_WORKS = "VEHICLES_SET_USER_WORKS";
export const VEHICLES_SET_CREATED_USER_WORK = "VEHICLES_SET_CREATED_USER_WORK";
export const VEHICLES_SET_DELETED_USER_WORK = "VEHICLES_SET_DELETED_USER_WORK";
export const VEHICLES_SET_UPDATED_USER_WORK = "VEHICLES_SET_UPDATED_USER_WORK";

/**
 * Orders Tasks
 */

export const VEHICLES_SET_ORDERS_TASKS = "VEHICLES_SET_ORDERS_TASKS";
export const VEHICLES_SET_ORDERS_TASKS_TOTAL_COUNT =
  "VEHICLES_SET_ORDERS_TASKS_TOTAL_COUNT";

/**
 * Order categories
 */
export const VEHICLES_SET_ORDER_CATEGORIES = "VEHICLES_SET_ORDER_CATEGORIES";
export const VEHICLES_SET_CREATED_ORDER_CATEGORY =
  "VEHICLES_SET_CREATED_ORDER_CATEGORY";
export const VEHICLES_SET_DELETED_ORDER_CATEGORY =
  "VEHICLES_SET_DELETED_ORDER_CATEGORY";

/**
 * Order groups
 */
export const VEHICLES_SET_ORDER_GROUPS = "VEHICLES_SET_ORDER_GROUPS";
export const VEHICLES_SET_CREATED_ORDER_GROUP =
  "VEHICLES_SET_CREATED_ORDER_GROUP";
export const VEHICLES_SET_DELETED_ORDER_GROUP_ID =
  "VEHICLES_SET_DELETED_ORDER_GROUP_ID";

/**
 * Items sharings
 */

export const VEHICLES_SET_ITEMS_SHARINGS = "VEHICLES_SET_ITEMS_SHARINGS";
export const VEHICLES_SET_CREATED_ITEMS_SHARING =
  "VEHICLES_SET_CREATED_ITEMS_SHARING";
export const VEHICLES_SET_DELETED_ITEMS_SHARING =
  "VEHICLES_SET_DELETED_ITEMS_SHARING";

/**
 * Items transfers
 */

export const VEHICLES_SET_ITEMS_TRANSFERS = "VEHICLES_SET_ITEMS_TRANSFERS";
export const VEHICLES_SET_ITEMS_TRANSFERS_TOTALCOUNT =
  "VEHICLES_SET_ITEMS_TRANSFERS_TOTALCOUNT";
export const VEHICLES_SET_ACCEPTED_ITEM_TRANSFER =
  "VEHICLES_SET_ACCEPTED_ITEM_TRANSFER";
export const VEHICLES_SET_REFUSED_ITEM_TRANSFER =
  "VEHICLES_SET_REFUSED_ITEM_TRANSFER";

/**
 * Pipeline Templates
 */

export const VEHICLES_SET_PIPELINE_TEMPLATES =
  "VEHICLES_SET_PIPELINE_TEMPLATES";
export const VEHICLES_SET_DELETED_PIPELINE_TEMPLATE =
  "VEHICLES_SET_DELETED_PIPELINE_TEMPLATE";
export const VEHICLES_SET_SELECTED_PIPELINE_TEMPLATES =
  "VEHICLES_SET_SELECTED_PIPELINE_TEMPLATES";

/**
 * Pipelines transfers
 */

export const VEHICLES_SET_PIPELINES_SHARINGS =
  "VEHICLES_SET_PIPELINES_SHARINGS";
export const VEHICLES_SET_CREATED_PIPELINES_SHARING =
  "VEHICLES_SET_CREATED_PIPELINES_SHARING";
export const VEHICLES_SET_DELETED_PIPELINES_SHARING =
  "VEHICLES_SET_DELETED_PIPELINES_SHARING";

/**
 * Orders
 */
export const VEHICLES_SET_ORDER = "VEHICLES_SET_ORDER";
export const VEHICLES_SET_CREATED_ORDER = "VEHICLES_SET_CREATED_ORDER";
export const VEHICLES_SET_ORDER_ACCEPTED = "VEHICLES_SET_ORDER_ACCEPTED";
export const VEHICLES_SET_ORDER_DECLINED = "VEHICLES_SET_ORDER_DECLINED";
export const VEHICLES_SET_ORDER_DONE = "VEHICLES_SET_ORDER_DONE";
export const VEHICLES_SET_ORDER_DELIVERED = "VEHICLES_SET_ORDER_DELIVERED";
export const VEHICLES_SET_ORDER_INVOICED = "VEHICLES_SET_ORDER_INVOICED";
export const VEHICLES_SET_ORDER_ACQUISITION_DONE =
  "VEHICLES_SET_ORDER_ACQUISITION_DONE";
export const VEHICLES_SET_UPDATED_ORDER_CONTACT_NAME =
  "VEHICLES_SET_UPDATED_ORDER_CONTACT_NAME";
export const VEHICLES_SET_ORDER_PAGEKEYS_NULL =
  "VEHICLES_SET_ORDER_PAGEKEYS_NULL";
export const VEHICLES_SET_ORDER_PAGEKEYS = "VEHICLES_SET_ORDER_PAGEKEYS";

export const VEHICLES_SET_ORDERS = "VEHICLES_SET_ORDERS";
export const VEHICLES_SET_ORDERS_COUNT = "VEHICLES_SET_ORDERS_COUNT";
export const VEHICLES_SET_ORDERS_TOTAL_COUNT =
  "VEHICLES_SET_ORDERS_TOTAL_COUNT";
export const VEHICLES_SET_ORDERS_NEXT_TOKEN = "VEHICLES_SET_ORDERS_NEXT_TOKEN";

export const VEHICLES_SET_ORDER_SETTED_TASK = "VEHICLES_SET_ORDER_SETTED_TASK";
export const VEHICLES_SET_ORDER_UNSETTED_TASK =
  "VEHICLES_SET_ORDER_UNSETTED_TASK";
export const VEHICLES_SET_ORDER_TASKS = "VEHICLES_SET_ORDER_TASKS";
export const VEHICLES_ORDERS_SET_USER_ASSIGNED_IN_TASK =
  "VEHICLES_ORDERS_SET_USER_ASSIGNED_IN_TASK";
export const VEHICLES_SET_ORDER_TASK_DONE = "VEHICLES_SET_ORDER_TASK_DONE";
export const VEHICLES_SET_ORDER_TASK_RUN = "VEHICLES_SET_ORDER_TASK_RUN";
export const VEHICLES_SET_ORDER_TASK_PAUSE = "VEHICLES_SET_ORDER_TASK_PAUSE";
export const VEHICLES_SET_ORDER_TASK_DATES = "VEHICLES_SET_ORDER_TASK_DATES";
export const VEHICLES_SET_ORDER_TASK_INTERNAL_RATE =
  "VEHICLES_SET_ORDER_TASK_INTERNAL_RATE";

export const VEHICLES_SET_ORDER_CURRENT_DAY_INCOMES =
  "VEHICLES_SET_ORDER_CURRENT_DAY_INCOMES";
export const VEHICLES_SET_ORDER_CURRENT_WEEK_INCOMES =
  "VEHICLES_SET_ORDER_CURRENT_WEEK_INCOMES";
export const VEHICLES_SET_ORDER_CURRENT_MONTH_INCOMES =
  "VEHICLES_SET_ORDER_CURRENT_MONTH_INCOMES";

export const VEHICLES_DECREMENT_ORDERS_TOTAL_COUNT =
  "VEHICLES_DECREMENT_ORDERS_TOTAL_COUNT";
export const VEHICLES_INCREMENT_ORDERS_TOTAL_COUNT =
  "VEHICLES_INCREMENT_ORDERS_TOTAL_COUNT";

export const VEHICLES_SET_ORDER_FEEDBACK = "VEHICLES_SET_ORDER_FEEDBACK";
export const VEHICLES_SET_CREATED_ORDER_FEEDBACK =
  "VEHICLES_SET_CREATED_ORDER_FEEDBACK";
export const VEHICLES_SET_CREATED_ORDER_FEEDBACK_COMMENT =
  "VEHICLES_SET_CREATED_ORDER_FEEDBACK_COMMENT";

export const VEHICLES_SET_USER_COMMENTS = "VEHICLES_SET_USER_COMMENTS";
export const VEHICLES_SET_CREATED_USER_COMMENT =
  "VEHICLES_SET_CREATED_USER_COMMENT";

export const VEHICLES_SET_CREATED_ORDER_MANAGER =
  "VEHICLES_SET_CREATED_ORDER_MANAGER";
export const VEHICLES_SET_DELETED_ORDER_MANAGER =
  "VEHICLES_SET_DELETED_ORDER_MANAGER";

export const VEHICLES_SET_CREATED_ORDER_REFERRERS =
  "VEHICLES_SET_CREATED_ORDER_REFERRERS";
export const VEHICLES_SET_DELETED_ORDER_REFERRERS =
  "VEHICLES_SET_DELETED_ORDER_REFERRERS";

export const VEHICLES_SET_CREATED_ORDER_CONTROLLER =
  "VEHICLES_SET_CREATED_ORDER_CONTROLLER";
export const VEHICLES_ORDERS_UPDATE_INTERNAL_RATE =
  "VEHICLES_ORDERS_UPDATE_INTERNAL_RATE";

/**
 * Work times
 */

export const VEHICLES_SET_WORK_TIMES = "VEHICLES_SET_WORK_TIMES";
export const VEHICLES_SET_CREATED_WORK_TIMES =
  "VEHICLES_SET_CREATED_WORK_TIMES";
export const VEHICLES_SET_DELETED_WORK_TIME = "VEHICLES_SET_DELETED_WORK_TIME";
export const VEHICLES_SET_WORK_TIME_CURRENT_DAY_COST =
  "VEHICLES_SET_WORK_TIME_CURRENT_DAY_COST";
export const VEHICLES_SET_WORK_TIME_CURRENT_WEEK_COST =
  "VEHICLES_SET_WORK_TIME_CURRENT_WEEK_COST";
export const VEHICLES_SET_WORK_TIME_CURRENT_MONTH_COST =
  "VEHICLES_SET_WORK_TIME_CURRENT_MONTH_COST";
export const VEHICLES_SET_UPDATED_WORK_TIME = "VEHICLES_SET_UPDATED_WORK_TIME";

/**
 * Products
 */

export const VEHICLES_SET_PRODUCTS = "VEHICLES_SET_PRODUCTS";
export const VEHICLES_SET_PRODUCT = "VEHICLES_SET_PRODUCT";
export const VEHICLES_SET_PRODUCTS_COUNT = "VEHICLES_SET_PRODUCTS_COUNT";
export const VEHICLES_SET_PRODUCTS_TOTAL_COUNT =
  "VEHICLES_SET_PRODUCTS_TOTAL_COUNT";
export const VEHICLES_SET_CREATED_PRODUCT = "VEHICLES_SET_CREATED_PRODUCT";
export const VEHICLES_SET_UPDATED_PRODUCT = "VEHICLES_SET_UPDATED_PRODUCT";
export const VEHICLES_SET_DELETED_PRODUCT = "VEHICLES_SET_DELETED_PRODUCT";
export const VEHICLES_SET_NEW_PRODUCT_DEFAULT_TASK =
  "VEHICLES_SET_NEW_PRODUCT_DEFAULT_TASK";
export const VEHICLES_SET_DELETED_PRODUCT_DEFAULT_TASK =
  "VEHICLES_SET_DELETED_PRODUCT_DEFAULT_TASK";
export const VEHICLES_SET_PRODUCT_COMPLETION_TIMES =
  "VEHICLES_SET_PRODUCT_COMPLETION_TIMES";

/**
 * Kboxs
 */

export const VEHICLES_SET_KBOXS = "VEHICLES_SET_KBOXS";
export const VEHICLES_SET_KBOX = "VEHICLES_SET_KBOX";
export const VEHICLES_SET_JOINED_KBOX = "VEHICLES_SET_JOINED_KBOX";
export const VEHICLES_SET_DELETED_KBOX = "VEHICLES_SET_DELETED_KBOX";

/**
 * Quotes
 */

export const VEHICLES_SET_QUOTES = "VEHICLES_SET_QUOTES";
export const VEHICLES_SET_QUOTES_TOTALCOUNT = "VEHICLES_SET_QUOTES_TOTALCOUNT";
export const VEHICLES_SET_QUOTE = "VEHICLES_SET_QUOTE";
export const VEHICLES_SET_DISCARDED_QUOTE = "VEHICLES_SET_DISCARDED_QUOTE";
export const VEHICLES_SET_REFUSED_QUOTE = "VEHICLES_SET_REFUSED_QUOTE";
export const VEHICLES_SET_ACCEPTED_QUOTE = "VEHICLES_SET_ACCEPTED_QUOTE";

/**
 * Pipelines
 */

export const VEHICLES_SET_PIPELINES = "VEHICLES_SET_PIPELINES";
export const VEHICLES_SET_PIPELINES_TOTALCOUNT =
  "VEHICLES_SET_PIPELINES_TOTALCOUNT";
export const VEHICLES_SET_PIPELINE = "VEHICLES_SET_PIPELINE";

/**
 * Task Definitions
 */

export const VEHICLES_SET_TASK_DEFINITIONS = "VEHICLES_SET_TASK_DEFINITIONS";
export const VEHICLES_SET_TASK_DEFINITION = "VEHICLES_SET_TASK_DEFINITION";
export const VEHICLES_SET_CREATED_TASK_DEFINITION =
  "VEHICLES_SET_CREATED_TASK_DEFINITION";
export const VEHICLES_SET_DELETED_TASK_DEFINITION =
  "VEHICLES_SET_DELETED_TASK_DEFINITION";
export const VEHICLES_SET_UPDATED_TASK_DEFINITION =
  "VEHICLES_SET_UPDATED_TASK_DEFINITION";
export const VEHICLES_SET_TASK_DEFINITION_COMPLETION_TIMES =
  "VEHICLES_SET_TASK_DEFINITION_COMPLETION_TIMES";

/**
 * Leaves
 */

export const VEHICLES_LEAVES_SET_LEAVES = "VEHICLES_LEAVES_SET_LEAVES";
export const VEHICLES_LEAVES_SET_LEAVE = "VEHICLES_LEAVES_SET_LEAVE";
export const VEHICLES_LEAVES_SET_LEAVES_TOTALCOUNT =
  "VEHICLES_LEAVES_SET_LEAVES_TOTALCOUNT";
export const VEHICLES_LEAVES_SET_CREATED_LEAVE =
  "VEHICLES_LEAVES_SET_CREATED_LEAVE";
export const VEHICLES_LEAVES_SET_ACCEPTED_LEAVE =
  "VEHICLES_LEAVES_SET_ACCEPTED_LEAVE";
export const VEHICLES_LEAVES_SET_REFUSED_LEAVE =
  "VEHICLES_LEAVES_SET_REFUSED_LEAVE";
export const VEHICLES_LEAVES_SET_DELETED_LEAVE =
  "VEHICLES_LEAVES_SET_DELETED_LEAVE";
export const VEHICLES_LEAVES_SET_UPDATED_LEAVE =
  "VEHICLES_LEAVES_SET_UPDATED_LEAVE";

/**
 * Feedback Alerts
 */

export const VEHICLES_SET_FEEDBACK_ALERTS = "VEHICLES_SET_FEEDBACK_ALERTS";
export const VEHICLES_SET_CREATED_FEEDBACK_ALERT =
  "VEHICLES_SET_CREATED_FEEDBACK_ALERT";
export const VEHICLES_SET_DELETED_FEEDBACK_ALERT =
  "VEHICLES_SET_DELETED_FEEDBACK_ALERT";
export const VEHICLES_SET_UPDATED_FEEDBACK_ALERT =
  "VEHICLES_SET_UPDATED_FEEDBACK_ALERT";

/**
 * Damage requests
 */

export const VEHICLES_DAMAGE_REQUESTS_SET_REQUESTS =
  "VEHICLES_DAMAGE_REQUESTS_SET_REQUESTS";
export const VEHICLES_DAMAGE_REQUESTS_SET_TOTALCOUNT =
  "VEHICLES_DAMAGE_REQUESTS_SET_TOTALCOUNT";
export const VEHICLES_DAMAGE_REQUESTS_SET_REQUEST =
  "VEHICLES_DAMAGE_REQUESTS_SET_REQUEST";
export const VEHICLES_DAMAGE_REQUESTS_SET_REQUEST_FOR_SUPPORT =
  "VEHICLES_DAMAGE_REQUESTS_SET_REQUEST_FOR_SUPPORT";
export const VEHICLES_DAMAGE_REQUESTS_SET_ACCEPTED_REQUEST =
  "VEHICLES_DAMAGE_REQUESTS_SET_ACCEPTED_REQUEST";
export const VEHICLES_DAMAGE_REQUESTS_SET_REFUSED_REQUEST =
  "VEHICLES_DAMAGE_REQUESTS_SET_REFUSED_REQUEST";

/**
 * Lates
 */
export const VEHICLES_LATES_SET_LATES = "VEHICLES_LATES_SET_LATES";
export const VEHICLES_LATES_SET_LATE = "VEHICLES_LATES_SET_LATE";
export const VEHICLES_LATES_SET_LATES_TOTALCOUNT =
  "VEHICLES_LATES_SET_LATES_TOTALCOUNT";
export const VEHICLES_LATES_SET_CREATED_LATE =
  "VEHICLES_LATES_SET_CREATED_LATE";
export const VEHICLES_LATES_SET_DELETED_LATE =
  "VEHICLES_LATES_SET_DELETED_LATE";

/**
 * Multi Dash groups
 */
export const VEHICLES_SET_MULTI_DASH_GROUPS = "VEHICLES_SET_MULTI_DASH_GROUPS";
export const VEHICLES_SET_CREATED_MULTI_DASH_GROUP =
  "VEHICLES_SET_CREATED_MULTI_DASH_GROUP";
export const VEHICLES_SET_DELETED_MULTI_DASH_GROUP_ID =
  "VEHICLES_SET_DELETED_MULTI_DASH_GROUP_ID";

/**
 * Projected costs
 */
export const VEHICLES_PROJECTED_COSTS_SET_PROJECTED_COSTS =
  "VEHICLES_PROJECTED_COSTS_SET_PROJECTED_COSTS";
export const VEHICLES_PROJECTED_COSTS_SET_PROJECTED_COST =
  "VEHICLES_PROJECTED_COSTS_SET_PROJECTED_COST";
export const VEHICLES_PROJECTED_COSTS_SET_PROJECTED_COSTS_TOTALCOUNT =
  "VEHICLES_PROJECTED_COSTS_SET_PROJECTED_COSTS_TOTALCOUNT";
export const VEHICLES_PROJECTED_COSTS_SET_CREATED_PROJECTED_COST =
  "VEHICLES_PROJECTED_COSTS_SET_CREATED_PROJECTED_COST";
export const VEHICLES_PROJECTED_COSTS_SET_UPDATED_PROJECTED_COST =
  "VEHICLES_PROJECTED_COSTS_SET_UPDATED_PROJECTED_COST";
export const VEHICLES_PROJECTED_COSTS_SET_DELETED_PROJECTED_COST =
  "VEHICLES_PROJECTED_COSTS_SET_DELETED_PROJECTED_COST";
