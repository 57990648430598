import createReducer from "../../lib/createReducer";
import * as types from "../../actions/types";

const initialState = {
  items: [],
  item: null,
  count: 0,
  totalCount: 0,
  productCustomPrices: {},
};

export default createReducer(initialState, {
  /**
   * CONTACTS GROUPS ===============================================================================
   */

  [types.VEHICLES_SET_CONTACTS_GROUPS_SET_GROUPS](state, action) {
    const newState = Object.assign({}, state, { items: action.state.items });
    return newState;
  },

  [types.VEHICLES_SET_CONTACTS_SET_GROUP](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    newState.item = action.state.item;
    return newState;
  },

  [types.VEHICLES_CONTACTS_GROUPS_SET_DELETED_GROUP](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { contactGroupId } = action.state;

    // Delete contact from contacts list
    newState.items = newState.items.filter((u) => u.id !== contactGroupId);

    return newState;
  },

  [types.VEHICLES_CONTACTS_GROUPS_CREATED_GROUP](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const contact = action.state.item;

    newState.items.unshift(contact);
    return newState;
  },

  [types.VEHICLES_CONTACTS_GROUPS_UPDATED_GROUP](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { contactGroupId, data } = action.state;
    newState.items = newState.items.map((grp) => {
      if (grp.id === contactGroupId) {
        grp.name = data.name;
        grp.comment = data.comment;
        return grp;
      }
      return grp;
    });

    if (newState.item && newState.item.id === contactGroupId) {
      newState.name = data.name;
      newState.comment = data.comment;
    }

    return newState;
  },

  /**
   * CONTACTS GROUPS COUNTS ========================================================================
   */

  [types.VEHICLES_CONTACTS_GROUPS_SET_GROUPS_COUNT](state, action) {
    const newState = Object.assign({}, state, { count: action.state.count });
    return newState;
  },

  [types.VEHICLES_CONTACTS_GROUPS_SET_GROUPS_TOTAL_COUNT](state, action) {
    const newState = Object.assign({}, state, {
      totalCount: action.state.totalCount,
    });
    return newState;
  },

  /**
   * PRODUCT CUSTOM PRICES =========================================================================
   */

  [types.VEHICLES_SET_CONTACTS_GROUPS_CUSTOM_PRICES](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { items, contactGroupId } = action.state;

    newState.productCustomPrices[contactGroupId] =
      newState.productCustomPrices[contactGroupId] || {};
    newState.productCustomPrices[contactGroupId].items = items;

    return newState;
  },

  [types.VEHICLES_CONTACTS_GROUPS_SET_IMPORTED_CUSTOM_PRICES](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { items, contactGroupId } = action.state;

    let data = newState.productCustomPrices[contactGroupId];

    data = data || {};
    data.items = data.items || [];
    if (items.length > 0) {
      items.forEach((item) => {
        data.items.unshift(item);
      });
      data.totalCount = data.totalCount || 0;
      data.totalCount += items.length;
    }

    newState.productCustomPrices[contactGroupId] = data;
    return newState;
  },

  [types.VEHICLES_CONTACTS_GROUPS_SET_CUSTOM_PRICES_TOTAL_COUNT](
    state,
    action
  ) {
    const newState = JSON.parse(JSON.stringify(state));
    const { totalCount, contactGroupId } = action.state;
    newState.productCustomPrices[contactGroupId] =
      newState.productCustomPrices[contactGroupId] || {};
    newState.productCustomPrices[contactGroupId].totalCount = totalCount;
    return newState;
  },

  [types.VEHICLES_CONTACTS_GROUPS_SET_CUSTOM_PRICES_COUNT](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { count, contactGroupId } = action.state;
    newState.productCustomPrices[contactGroupId] =
      newState.productCustomPrices[contactGroupId] || {};
    newState.productCustomPrices[contactGroupId].count = count;
    return newState;
  },

  [types.VEHICLES_SET_CONTACT_PRODUCT_CUSTOM_PRICES_NEXT_TOKEN](state, action) {
    const newState = Object.assign({}, state, {
      nextToken: action.state.nextToken,
    });
    return newState;
  },

  [types.VEHICLES_SET_CONTACTS_GROUPS_CREATED_CUSTOM_PRICE](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { item, contactGroupId } = action.state;

    let data = newState.productCustomPrices[contactGroupId];

    data = data || {};
    data.items = data.items || [];
    data.items.unshift(item);
    data.totalCount = data.totalCount || 0;
    data.totalCount += 1;
    newState.productCustomPrices[contactGroupId] = data;

    return newState;
  },

  [types.VEHICLES_CONTACTS_GROUPS_SET_DELETED_CUSTOM_PRICE](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { productId, contactGroupId } = action.state;

    let data = newState.productCustomPrices[contactGroupId];

    data = data || {};
    data.items = data.items || [];
    data.items = newState.productCustomPrices[contactGroupId].items.filter(
      (u) => u.id !== productId
    );
    data.totalCount = data.totalCount || 0;
    data.totalCount -= 1;
    newState.productCustomPrices[contactGroupId] = data;

    return newState;
  },

  [types.VEHICLES_CONTACTS_GROUPS_UPDATED_CUSTOM_PRICE](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { contactGroupId, productId, payload } = action.state;

    const data = newState.productCustomPrices[contactGroupId];

    if (data) {
      data.items.forEach((item, index) => {
        if (item.id === productId) {
          data.items[index].price = payload.price;
          data.items[index].secondSeries = payload.secondSeries;
        }
      });

      newState.productCustomPrices[contactGroupId] = data;
    }

    return newState;
  },
});
