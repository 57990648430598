import createReducer from "../../lib/createReducer";
import * as types from "../../actions/types";

const initialState = {
  item: null,
  items: [],

  totalCount: 0,
};

export default createReducer(initialState, {
  [types.VEHICLES_SET_QUOTES](state, action) {
    const newState = Object.assign({}, state, { items: action.state.items });

    return newState;
  },

  [types.VEHICLES_SET_QUOTES_TOTALCOUNT](state, action) {
    const newState = Object.assign({}, state, {
      totalCount: action.state.totalCount,
    });

    return newState;
  },

  [types.VEHICLES_SET_QUOTE](state, action) {
    const newState = Object.assign({}, state, { item: action.state.item });

    return newState;
  },

  [types.VEHICLES_SET_DISCARDED_QUOTE](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    newState.items = newState.items.filter(
      (i) => i.id !== action.state.quoteId
    );

    if (newState.item && newState.item.id === action.state.quoteId) {
      newState.item.state = 5;
    }

    newState.totalCount -= 1;

    return newState;
  },

  [types.VEHICLES_SET_REFUSED_QUOTE](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    newState.items = newState.items.filter(
      (i) => i.id !== action.state.quoteId
    );

    if (newState.item && newState.item.id === action.state.quoteId) {
      newState.item.state = 10;
    }

    newState.totalCount -= 1;

    return newState;
  },

  [types.VEHICLES_SET_ACCEPTED_QUOTE](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    newState.items = newState.items.filter(
      (i) => i.id !== action.state.quoteId
    );

    if (newState.item && newState.item.id === action.state.quoteId) {
      newState.item.state = 15;
    }

    newState.totalCount -= 1;

    return newState;
  },
});
