import React, { lazy } from "react";
import { Switch, Route } from "react-router-dom";
import suspensify from "../boot/suspensify";

const AuthLayout = lazy(() =>
  import(
    /* webpackChunkName: "AuthLayoutComponent" */
    "../components/layouts/AuthLayout"
  )
);

const Login = lazy(() =>
  import(
    /* webpackChunkName: "AuthLoginComponent" */
    "../containers/auth/Login"
  )
);

const ForgotPassword = lazy(() =>
  import(
    /* webpackChunkName: "AuthForgotPasswordComponent" */
    "../containers/auth/ForgotPassword"
  )
);

const ForgotPasswordConfirm = lazy(() =>
  import(
    /* webpackChunkName: "AuthForgotPasswordConfirmComponent" */
    "../containers/auth/ForgotPasswordConfirm"
  )
);

const ConfirmAccount = lazy(() =>
  import(
    /* webpackChunkName: "AuthConfirmAccountComponent" */
    "../containers/auth/ConfirmAccount"
  )
);

const SelectEntity = lazy(() =>
  import(
    /* webpackChunkName: "AuthSelectEntityComponent" */
    "../containers/auth/SelectEntity"
  )
);

export default function Auth(props) {
  return (
    <AuthLayout {...props}>
      <Switch>
        <Route
          exact
          path="/auth/login"
          component={suspensify(Login, "pageLoading")}
        />

        <Route
          exact
          path="/auth/confirm-account"
          component={suspensify(ConfirmAccount, "pageLoading")}
        />

        <Route
          exact
          path="/auth/forgot-password"
          component={suspensify(ForgotPassword, "pageLoading")}
        />

        <Route
          exact
          path="/auth/forgot-password-confirm"
          component={suspensify(ForgotPasswordConfirm, "pageLoading")}
        />

        <Route
          exact
          path="/auth/select-entity"
          component={suspensify(SelectEntity, "pageLoading")}
        />
      </Switch>
    </AuthLayout>
  );
}
