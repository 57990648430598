import createReducer from "../../lib/createReducer";
import * as types from "../../actions/types";

const initialState = {
  items: [],
  totalCount: 0,
  item: null,
};

export default createReducer(initialState, {
  [types.VEHICLES_LATES_SET_LATES](state, action) {
    const newState = Object.assign({}, state, { items: action.state.items });

    return newState;
  },

  [types.VEHICLES_LATES_SET_LATES_TOTALCOUNT](state, action) {
    const newState = Object.assign({}, state, {
      totalCount: action.state.totalCount,
    });

    return newState;
  },

  [types.VEHICLES_LATES_SET_LATE](state, action) {
    const newState = Object.assign({}, state, { item: action.state.item });

    return newState;
  },

  [types.VEHICLES_LATES_SET_CREATED_LATE](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { item } = action.state;

    newState.items.unshift(item);

    return newState;
  },

  [types.VEHICLES_LATES_SET_DELETED_LATE](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    const { lateId } = action.state;
    newState.items = newState.items.filter((i) => i.id !== lateId);

    return newState;
  },
});
