import Amplify from "aws-amplify";
import { AWSIoTProvider } from "@aws-amplify/pubsub/lib/Providers";

Amplify.configure({
  Auth: {
    identityPoolId: window.appConfig.AWSCognitoIdentityPoolId,
    region: window.appConfig.AWSRegion,
    userPoolId: window.appConfig.AWSCognitoUserPoolId,
    userPoolWebClientId: window.appConfig.AWSCognitoClientId,
  },
});

export function configurePubSub(clientId) {
  Amplify.addPluggable(
    new AWSIoTProvider({
      clientId,
      aws_pubsub_region: window.appConfig.AWSRegion,
      aws_pubsub_endpoint: window.appConfig.AWSPubSubEndpoint,
    })
  );
}

export default Amplify;
