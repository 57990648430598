import createReducer from "../../lib/createReducer";
import * as types from "../../actions/types";

const initialState = {
  items: [],
  totalCount: 0,
};

export default createReducer(initialState, {
  [types.VEHICLES_SET_ITEMS_TRANSFERS](state, action) {
    const newState = Object.assign({}, state, { items: action.state.items });

    return newState;
  },

  [types.VEHICLES_SET_ITEMS_TRANSFERS_TOTALCOUNT](state, action) {
    const newState = Object.assign({}, state, {
      totalCount: action.state.totalCount,
    });

    return newState;
  },

  [types.VEHICLES_SET_ACCEPTED_ITEM_TRANSFER](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    newState.items = newState.items.filter(
      (i) => i.id !== action.state.itemTransferID
    );
    newState.totalCount -= 1;

    return newState;
  },

  [types.VEHICLES_SET_REFUSED_ITEM_TRANSFER](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    newState.items = newState.items.filter(
      (i) => i.id !== action.state.itemTransferID
    );
    newState.totalCount -= 1;

    return newState;
  },
});
