import createReducer from "../../lib/createReducer";
import * as types from "../../actions/types";

const initialState = {
  items: [],
  createdItem: null,
};

export default createReducer(initialState, {
  [types.VEHICLES_SET_ITEMS_SHARINGS](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    newState.items = action.state.items;

    return newState;
  },

  [types.VEHICLES_SET_CREATED_ITEMS_SHARING](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    newState.createdItem = action.state.item;
    newState.items.unshift(newState.createdItem);

    return newState;
  },

  [types.VEHICLES_SET_DELETED_ITEMS_SHARING](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    const { entityId } = action.state;
    newState.items = newState.items.filter((i) => i.entityId !== entityId);

    return newState;
  },
});
