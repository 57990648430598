import createReducer from "../../lib/createReducer";
import * as types from "../../actions/types";

const initialState = {
  createdItem: null,
  items: [],
  currentDayCost: 0,
  currentWeekCost: 0,
  currentMonthCost: 0,
};

export default createReducer(initialState, {
  [types.VEHICLES_SET_WORK_TIMES](state, action) {
    const newState = JSON.parse(JSON.stringify(state));

    Object.assign(newState, {
      items: action.state.items,
    });

    return newState;
  },

  [types.VEHICLES_SET_CREATED_WORK_TIMES](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { items } = action.state;

    Object.assign(newState, {
      createdItem: items,
    });

    newState.items.push(...items);

    return newState;
  },

  [types.VEHICLES_SET_DELETED_WORK_TIME](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { id } = action.state;

    newState.items = newState.items.filter((i) => i.id !== id);

    return newState;
  },

  [types.VEHICLES_SET_WORK_TIME_CURRENT_DAY_COST](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { cost } = action.state;

    newState.currentDayCost = cost;

    return newState;
  },

  [types.VEHICLES_SET_WORK_TIME_CURRENT_WEEK_COST](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { cost } = action.state;

    newState.currentWeekCost = cost;

    return newState;
  },

  [types.VEHICLES_SET_WORK_TIME_CURRENT_MONTH_COST](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { cost } = action.state;

    newState.currentMonthCost = cost;

    return newState;
  },

  [types.VEHICLES_SET_UPDATED_WORK_TIME](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    const { item } = action.state;

    newState.items = newState.items.map((i) => {
      if (i.id === item.id) {
        return item;
      }
      return i;
    });

    return newState;
  },
});
