import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import { Router } from "react-router-dom";

import { ConfigProvider } from "antd";
import frFR from "antd/es/locale/fr_FR";

import moment from "moment";
import store from "./store";
import history from "./history";
import routes from "./routes/Index";

import Suspense from "./components/utils/Suspense";
import ErrorBoundary from "./components/ErrorBoundary";

// Import moment fr locale
import "moment/locale/fr";

// Configure moment locale
moment.locale("fr");

// Get mount element for react app
const mountElement = document.getElementById("mount");

const App = () => (
  <ErrorBoundary>
    <Provider store={store}>
      <Suspense>
        <Router history={history}>{routes}</Router>
      </Suspense>
    </Provider>
  </ErrorBoundary>
);

if (mountElement) {
  ReactDOM.render(
    <ConfigProvider locale={frFR}>
      <App />
    </ConfigProvider>,
    mountElement
  );
}

export default history;
