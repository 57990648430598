import React, { lazy } from "react";
import { Switch, Route } from "react-router-dom";
import suspensify from "../boot/suspensify";

// Main app component
import App from "../components/App";

// Apps
import Account from "./Account";
import Auth from "./Auth";
import Company from "./Company";
import Vehicles from "./Vehicles";

// Libs
const Index = lazy(() =>
  import(
    /* webpackChunkName: "IndexComponent" */
    "../containers/Index"
  )
);

const GoTo = lazy(() =>
  import(
    /* webpackChunkName: "GoToComponent" */
    "../containers/GoTo"
  )
);

const OldDomainRedirect = lazy(() =>
  import(
    /* webpackChunkName: "OldDomainRedirectComponent" */
    "../containers/OldDomainRedirect"
  )
);

const NotFound = lazy(() =>
  import(
    /* webpackChunkName: "NotFoundComponent" */
    "../containers/NotFound"
  )
);

export default (
  <App>
    <Switch>
      {/* Index ================================================================================ */}

      <Route exact path="/" component={suspensify(Index, "pageLoading")} />

      {/* Apps ================================================================================= */}

      <Route exact path="/auth*">
        <Auth />
      </Route>

      <Route exact path="/account*">
        <Account />
      </Route>

      <Route exact path="/company*">
        <Company />
      </Route>

      <Route exact path="/vehicles*">
        <Vehicles />
      </Route>

      {/* GOTO ================================================================================= */}

      <Route exact path="/goto" component={suspensify(GoTo, "pageLoading")} />

      {/* OLD DOMAIN REDIRECT ================================================================== */}

      <Route
        exact
        path="/old-domain-redirect"
        component={suspensify(OldDomainRedirect, "pageLoading")}
      />

      <Route exact path="/" component={suspensify(Index, "pageLoading")} />

      {/* Not Found ============================================================================ */}

      <Route exact path="*" component={suspensify(NotFound, "pageLoading")} />
    </Switch>
  </App>
);
