import * as React from "react";
import { Spin } from "antd";
import Suspense from "../components/utils/Suspense";

export default function suspensify(comp, fallback) {
  return (props) => {
    const suspenseProps = {};

    if (fallback) {
      if (fallback === "pageLoading") {
        suspenseProps.fallback = (
          <div className="fh ce">
            <Spin />
          </div>
        );
      } else {
        suspenseProps.fallback = fallback;
      }
    }

    return (
      <Suspense {...suspenseProps}>{React.createElement(comp, props)}</Suspense>
    );
  };
}
