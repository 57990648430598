import React, { lazy } from "react";
import { Switch, Route } from "react-router-dom";
import suspensify from "../boot/suspensify";

const AccountLayout = lazy(() =>
  import(
    /* webpackChunkName: "AccountLayoutComponent" */
    "../components/layouts/AccountLayout"
  )
);

/**
 * INFORMATIONS ======================
 */

const AccountInformations = lazy(() =>
  import(
    /* webpackChunkName: "AccountInformationsComponent" */
    "../containers/account/Informations"
  )
);

/**
 * PASSWORD ==========================
 */

const AccountPassword = lazy(() =>
  import(
    /* webpackChunkName: "AccountPasswordComponent" */
    "../containers/account/Password"
  )
);

/**
 * APP PARAMS ========================
 */

const AccountAppParams = lazy(() =>
  import(
    /* webpackChunkName: "AccountAppParamsComponent" */
    "../containers/account/AppParams"
  )
);

export default function Account() {
  return (
    <AccountLayout>
      <Switch>
        {/* Informations */}

        <Route
          exact
          path="/account/informations"
          component={suspensify(AccountInformations, "pageLoading")}
        />

        {/* Password */}

        <Route
          exact
          path="/account/password"
          component={suspensify(AccountPassword, "pageLoading")}
        />

        {/* App params */}

        <Route
          exact
          path="/account/appparams"
          component={suspensify(AccountAppParams, "pageLoading")}
        />
      </Switch>
    </AccountLayout>
  );
}
