import createReducer from "../../lib/createReducer";
import * as types from "../../actions/types";

const initialState = {
  selectedRole: null,
  currentUserRoles: [],
};

export default createReducer(initialState, {
  [types.ACCOUNT_SET_CURRENT_USER_ROLES](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    newState.currentUserRoles = action.state.currentUserRoles;

    return newState;
  },

  [types.ACCOUNT_SET_SELECTED_ROLE](state, action) {
    const newState = JSON.parse(JSON.stringify(state));
    newState.selectedRole = action.state.selectedRole;

    return newState;
  },
});
