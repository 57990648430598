import { combineReducers } from "redux";
import breadcrump from "./breadcrump";
import app from "./app";
import account from "./account";
import vehicles from "./vehicles";

export default {
  app,
  breadcrump,
  account: combineReducers(account),
  vehicles: combineReducers(vehicles),
};
